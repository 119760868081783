import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ArticleCommentRes, UserRole, ArticleRes } from 'blocal-types';
import styled from 'styled-components';
import { isBefore } from 'date-fns';
import Author from '../Author';
import { formatDateTime } from '../../../../../../utils/formatDateTime';
import { RootState } from '../../../../../../reducers';
import { __ } from '../../../../../../helpers/i18n';
import Actions from './Actions';

interface Props {
  article: ArticleRes;
  comment: ArticleCommentRes;
  getArticle: () => void;
  commentParentId?: string;
}

const CommentWrapper = styled.div`
  display: flex;
  min-width: 200px;
  margin: ${({ theme }) => `calc(1.5 * ${theme.mainSpacing})`} 0;
  position: relative;
`;

const CommentMetaData = styled.div`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing})`};
  font-size: 0.75em;
  font-weight: 700;
`;

const CommentAuthor = styled.div`
  font-size: 0.9em;
  font-weight: 700;
  display: block;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing})`};
`;

const CommentContent = styled.div`
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  padding: ${({ theme }) => `${theme.mainSpacing}`};
  background-color: ${({ theme }) => `${theme.colors.colorInput}`};
  font-weight: 300;
  display: inline-block;
  word-break: break-word;
`;

const AdditionalMeta = styled.span`
  margin-left: ${({ theme }) => `calc(${theme.mainSpacing}/2)`};
  font-weight: 400;
  opacity: 0.7;
`;

const CommentData = styled.div`
  width: 100%;
`;

const SingleComment: FC<Props> = ({ comment, article, commentParentId, getArticle }) => {
  const getAdditionalMeta = () => {
    const meta = [];
    if ([UserRole.SuperAdmin, UserRole.Admin, UserRole.Moderator].includes(user!.role)) {
      if (comment.deleted) meta.push(__('article.commentRemoved'));
      if (comment.deletedByUser) meta.push(__('article.commentRemovedByUser'));
      if (comment.deletedByModerator) meta.push(__('article.commentRemovedByModerator'));
      if (comment.isAnonymous) meta.push(__('article.anonymousComment'));
    }
    return meta.join(', ');
  };

  const user = useSelector((state: RootState) => state.user.details);
  return (
    <CommentWrapper>
      <Author id={comment.authorId ? comment.authorId : null} />
      <CommentData>
        {comment.date && (
          <CommentMetaData>
            <strong>{formatDateTime(comment.date)}</strong>
            <AdditionalMeta>{getAdditionalMeta()}</AdditionalMeta>
          </CommentMetaData>
        )}
        <CommentContent>
          <CommentAuthor>
            {comment.author === 'anonymous' ? __('article.anonymousAuthor') : comment.author}
          </CommentAuthor>
          {comment.content}
        </CommentContent>
        <Actions comment={comment} article={article} commentParentId={commentParentId} getArticle={getArticle} />
        {comment.comments &&
          comment.comments
            .sort((a, b) => (isBefore(new Date(a.date!), new Date(b.date!)) ? 1 : -1))
            .map((subcomment) => (
              <SingleComment
                article={article}
                comment={subcomment}
                commentParentId={comment.id}
                getArticle={getArticle}
              />
            ))}
      </CommentData>
    </CommentWrapper>
  );
};

export default SingleComment;
