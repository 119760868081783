import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { __ } from '../../../helpers/i18n';

import {
  space,
  typography,
  color,
  flexbox,
  position,
  layout,
  TypographyProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
} from 'styled-system';

interface StyledIconButtonProps
  extends TypographyProps,
    ColorProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps {
  as?: 'button' | 'div';
  bRadius?: string;
  title?: string;
  disabled?: boolean;
  withShadow?: boolean;
  type?: string;
}

const StyledIconButton = styled.button<StyledIconButtonProps>`
  border-radius: ${({ bRadius, theme }) => (bRadius ?? `${theme.borderRadius}px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  position: relative;
  border: none;
  padding: 0;

  ${color}
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  ${({ withShadow }) =>
    withShadow &&
    css`
      box-shadow: ${({ theme }) =>
        `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(
          theme.colors.colorDark,
          0.25,
        )}`};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.colorDisabled};

      &:hover {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: ${({ theme }) => theme.colors.colorDisabled};
      }
    `}
`;

interface Props extends StyledIconButtonProps {
  onClick?: any;
  to?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const IconButton: FC<Props> = ({
  bRadius,
  disabled,
  color,
  onClick,
  children,
  to,
  title,
  ...props
}) => 
 to ? (
    <Link to={to}>
      <StyledIconButton
        as="div"
        onClick={onClick}
        disabled={disabled}
        bRadius={bRadius}
        title={title && __(title)}
        {...props}
      >
        {children}
      </StyledIconButton>
    </Link>
  ) : (
    <StyledIconButton
      onClick={onClick}
      disabled={disabled}
      bRadius={bRadius}
      title={title && __(title)}
      type="button"
      {...props}
    >
      {children}
    </StyledIconButton>
  );

IconButton.defaultProps = {
  mr: '10px',
  width: 'auto',
  bg: '#000',
};
