import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';

import { Column } from '../../Layout';
import { ArticleTitle } from '../../../components/Styled/ArticleTitle';
import { Text, InfoBox } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { usePlatform } from '../../../hooks/usePlatform';
import { QuizCard } from './QuizCard';
import { EventCard } from './EventCard';

type Use = 'article' | 'event' | 'quiz' | 'presentation';
export interface CardProps {
  title: string;
  isFirst?: boolean;
  to?: string;
  li?: boolean;
  imgUrl?: string;
  extraText?: string;
  completed?: boolean;
  points?: string;
  time?: string;
  place?: string;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  specialTask?: boolean;
  as?: string;
  use?: Use;
  textBelow?: boolean;
}

export const StyledImage = styled.img<{ isFirst?: boolean }>`
  height: ${({ isFirst }) => (isFirst ? '250px' : '200px')};
  display: block;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
`;

const StyledShortcut = styled.div<{ specialTask?: boolean; isFirst?: boolean }>`
  min-height: 50px;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.colorDark};
  color: ${({ theme }) => theme.colors.colorLight};
  padding: ${({ theme }) =>
    `calc(1.5 * ${theme.mainSpacing}) ${theme.mainSpacing} ${theme.mainSpacing}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ specialTask }) =>
    specialTask &&
    css`
      background-color: ${({ theme }) => theme.colors.colorLight};
      color: ${({ theme }) => theme.colors.colorDark};
      text-transform: uppercase;
    `}
  ${({ isFirst }) =>
    isFirst &&
    css`
      height: 0;
    `}
`;
export const StyledInfo = styled.div`
  min-height: 50px;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.colorLight};
  color: ${({ theme }) => theme.colors.colorDark};
  padding: ${({ theme }) => theme.mainSpacing};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
`;

const StyledCard = styled.div<{ noShadow: boolean }>`
  position: relative;
  margin-bottom: ${({ theme }) => `calc(1 * ${theme.mainSpacing})`};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  ${({ noShadow }) =>
    !noShadow &&
    css`
      box-shadow: ${({ theme }) =>
        `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(
          theme.colors.colorDark,
          0.25,
        )}`};
    `}
  overflow: hidden;
`;

const StyledDeadlineBox = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  padding: ${({ theme }) => `calc(.5 * ${theme.mainSpacing})`};
  margin-top: -50px;
  position: relative;
  z-index: 1;
`;

export const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.colorDark};
  text-decoration: none;
  position: relative;
  img {
    transition: 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

const CardTitle = styled.h2`
  font-weight: 700;
  font-size: 2rem;
`;

const Card: FC<CardProps> = ({
  title,
  extraText,
  isFirst,
  imgUrl,
  to,
  completed,
  points,
  place,
  date,
  startDate,
  endDate,
  time,
  specialTask,
  textBelow,
  use,
}) => {
  const { tenantQuery } = usePlatform();

  let cardContent = null;
  const setCardContent = () => {
    switch (use) {
      case 'event':
        cardContent = (
          <EventCard
            imgUrl={imgUrl}
            extraText={extraText}
            title={title}
            date={date}
            startDate={startDate}
            endDate={endDate}
            place={place}
            to={to}
          />
        );
        break;
      case 'presentation':
        cardContent = (
          <StyledLink to={to || '#'}>
            {imgUrl && (
              <StyledImage
                isFirst={isFirst}
                src={`${ApiService.url}${imgUrl}/${tenantQuery}`}
                alt=""
              />
            )}
            {extraText && !textBelow && <p>{extraText}</p>}
            {time && (
              <StyledDeadlineBox>
                <InfoBox text={time} />
              </StyledDeadlineBox>
            )}
            {isFirst && !textBelow && <ArticleTitle title={title} />}
            {!isFirst && !textBelow && (
              <StyledShortcut specialTask={specialTask}>
                {title && (
                  <Text as="h2" mb={1}>
                    {title}
                  </Text>
                )}
                {points && <InfoBox completed={completed} text={points} />}
              </StyledShortcut>
            )}
          </StyledLink>
        );
        break;
      case 'quiz':
        cardContent = (
          <QuizCard points={points} extraText={extraText} title={title} to={to} imgUrl={imgUrl} />
        );
        break;
      default:
        cardContent = (
          <StyledLink to={to || '#'}>
            {imgUrl && (
              <StyledImage
                isFirst={isFirst}
                src={`${ApiService.url}${imgUrl}/${tenantQuery}`}
                alt=""
              />
            )}
            {extraText && <p>{extraText}</p>}
            {time && (
              <StyledDeadlineBox>
                <InfoBox text={time} />
              </StyledDeadlineBox>
            )}
            {isFirst && <ArticleTitle title={title} />}
            {!isFirst && (
              <StyledShortcut specialTask={specialTask}>
                {title && (
                  <Text as="h2" mb={1}>
                    {title}
                  </Text>
                )}
                {points && <InfoBox completed={completed} text={points} />}
              </StyledShortcut>
            )}
          </StyledLink>
        );
        break;
    }
  };

  setCardContent();
  return (
    <Column width={isFirst ? 1 : [1, null, 1 / 3]}>
      <StyledCard noShadow={use === 'event'}>{cardContent}</StyledCard>
      {textBelow && <CardTitle>{title}</CardTitle>}
      {textBelow && extraText && <p>{extraText}</p>}
    </Column>
  );
};

export default Card;
