import React, { FC } from 'react';
import { UserRole } from 'blocal-types';
import { useSelector } from 'react-redux';
import { Container } from '../../Layout';
import { RootState } from '../../../reducers';
import { SelectTenantToOrder, SuperAdminBoxes, UserBoxes } from '..';
import { BasicStatistics } from '../../../views/Dashboard/Start/BasicStatistics';

const UserActions: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);
  const selectedPlatormId = useSelector(
    (state: RootState) => state?.selectedTenantAndModules?.tenant?.value,
  );
  const isSuperAdmin = user?.role === UserRole.SuperAdmin;

  return (
    <>
      <section>
        <Container>
          {isSuperAdmin && <SelectTenantToOrder />}
          {isSuperAdmin ? <SuperAdminBoxes /> : <UserBoxes />}
        </Container>
      </section>
      {isSuperAdmin && selectedPlatormId && <BasicStatistics />}
    </>
  );
};

export default UserActions;
