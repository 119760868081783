import { TenantSegment, CustomTileRes, UserRole } from 'blocal-types';
import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiUrl } from '../../../config/apiUrl';
import { ActionsBoxesContainer } from '..';
import { RootState } from '../../../reducers';
import { useApi } from '../../../services/useApi';
import UserActionBox from '../UserActionBox';
import { refreshUserDetails } from '../../../slices/userSlice';

// eslint-disable-next-line import/prefer-default-export
export const UserBoxes: FC = () => {
  const { api } = useApi();
  const [modulesForTenant, setModulesForTenant] = useState<TenantSegment[]>();
  const [tilesForUser, setTilesForUser] = useState<CustomTileRes[]>();
  const tenantId = useSelector((state: RootState) => state.platformConfig?.config?._id);
  const user = useSelector((state: RootState) => state.user?.details);
  const dispatch = useDispatch();

  if (!user) dispatch(refreshUserDetails());

  const renderCorrectModules = (resModules: TenantSegment[]) => {
    if (UserRole.Moderator === user?.role && resModules) {
      const isIncluded = (module: TenantSegment) =>
        user.userModules.some((userModule: any) => userModule.value === module._id);
      setModulesForTenant(resModules.filter((module) => isIncluded(module)));
    } else {
      setModulesForTenant(resModules);
    }
  };

  useEffect(() => {
    api<TenantSegment[]>({
      method: 'GET',
      path: 'tenant/available-segments',
      onSuccess: (res) => {
        renderCorrectModules(res);
      },
    });
    api<CustomTileRes[]>({
      method: 'GET',
      path: 'custom-tile/available-tiles',
      onSuccess: (res) => {
        setTilesForUser(res);
      },
    });
  }, []);

  return (
    <ActionsBoxesContainer>
      {modulesForTenant?.map((box) => {
        const { segment, segmentBg, segmentNameForTenant, _id, fileFn } = box;
        return (
          <UserActionBox
            key={_id}
            backgroundColor={segmentBg}
            text={segmentNameForTenant}
            path={segment.path}
            backgroundUrl={fileFn && `${apiUrl}tenant/module-photo/${tenantId}/${_id}`}
          />
        );
      }) ?? null}
      {tilesForUser?.map((tile: CustomTileRes) => {
        const { path, color, _id, fileFn, title } = tile;
        return (
          <UserActionBox
            key={_id}
            backgroundColor={color}
            text={title}
            path={path}
            backgroundUrl={fileFn && `${apiUrl}custom-tile/tile-photo/${tenantId}/${_id}`}
          />
        );
      }) ?? null}
    </ActionsBoxesContainer>
  );
};
