import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { rgba } from 'polished';
import { Image } from '../..';
import { Link } from 'react-router-dom';
import { ArticleRes } from 'blocal-types';
import { ArticleTitle } from '../../../Styled/ArticleTitle';
import { SwiperNavigation } from '../../SwiperNavigation';
import { apiUrl } from '../../../../config/apiUrl';

interface Props {
  news: ArticleRes[];
}

const StyledLink = styled(Link)`
  height: 240px;
  position: relative;
  overflow: hidden;
  width: 100%;
`;
const StyledWrapper = styled.div`
  margin-bottom: 20px;
  height: 240px;
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  cursor: pointer;
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(theme.colors.colorDark, 0.25)}`};
  overflow: hidden;
  &:hover {
    img {
      transition: 0.3s;
      transform: scale(1.01);
    }
  }
  a {
    display: block;
    height: 100%;
  }
`;

export const NewsCarosuel: FC<Props> = ({ news }) => {
  return (
    <Swiper
    navigation= {{
      hideOnClick: true,
      nextEl: '.swiper-button-next-unique',
      prevEl: '.swiper-button-prev-unique',
    }}
    slidesPerView={1}
    >
      {news?.map(({ title, _id }) => (
        <SwiperSlide key={_id}>
          <StyledWrapper>
            <StyledLink to={`/dashboard/articles/view/${_id}`}>
              <Image src={`${apiUrl}article/image/articleFn/${_id}`} alt="" />
              <ArticleTitle title={title} />
            </StyledLink>
          </StyledWrapper>
        </SwiperSlide>
      ))}
      <SwiperNavigation />
    </Swiper>
  );
};
