import styled from 'styled-components';
import { rgba } from 'polished';

// eslint-disable-next-line import/prefer-default-export
export const RoundedWithShadow = styled.div`
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(theme.colors.colorDark, 0.25)}`};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`;
