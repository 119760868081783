/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { FilePreview } from '..';
import { MultimediaFile } from '../../../types/photo';
import { FormFieldTypes } from '../../../types/formFieldTypes';
import { StyledSelect, StyledFile } from '../../Styled';
import { __ } from '../../../helpers/i18n';
import { InputLabel } from '../InputLabel';

interface StyledFormFieldErrorProps {
  big?: boolean;
}

const InputIcon = styled.button`
  margin-left: -3.5rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  opacity: 0.4;
  transition: 0.3s;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  &:hover {
    opacity: 0.5;
  }
`;

export const StyledFormFieldError = styled.span<StyledFormFieldErrorProps>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1rem  ')};
  display: block;
  color: ${({ theme }) => theme.colors.colorDanger};
  margin: ${({ theme }) => `calc(${theme.mainSpacing}) 0`};
`;

interface FormFieldInputProps {
  type: FormFieldTypes;
  name: string;
  editId?: string | number;
  accept?: string;
  placeholderText?: string;
  isRequired?: boolean;
  id?: string;
  max?: number;
  value?: string;
  description?: string;
  multiple?: boolean;
  isFile?: boolean;
  quizFile?: string;
  video?: boolean;
  options?: any[];
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  stringSelect?: boolean;
  filePath?: string | null;
  customFilePath?: string;
  findByName?: (fieldName: string) => MultimediaFile[];
  deleteContent?: () => void;
  defaultValue?: any;
  fieldArrayName?: string;
  readonly?: boolean;
  labelText?: string | React.ReactNode;
  iconClick?: () => void;
  min?: string;
}

const registerRef = (register: any, isRequired?: boolean) => {
  return isRequired
    ? register({ required: 'To pole jest wymagane' })
    : register({ required: false });
};
interface DraggableInput {
  draggableInput?: boolean;
}

interface StyledFormFieldProps extends DraggableInput {
  checkbox?: boolean;
  textarea?: boolean;
  customColor?: boolean;
  icon?: IconProp;
  mb0?: boolean;
  onDragOver?: any;
  onDragEnter?: any;
  onDragLeave?: any;
  onDragEnd?: any;
  onDrop?: any;
}

interface Props extends FormFieldInputProps, StyledFormFieldProps {
  labelText?: string | React.ReactNode;
  labelClassname?: string;
  field?: 'input' | React.ReactNode;
  isRequired?: boolean;
  min?: string;
  checked?: boolean;
}

const StyledWrapper = styled.div<DraggableInput>`
  ${({ draggableInput }) =>
    draggableInput &&
    css`
      position: relative;
    `}
`;

const FlexContainer = styled.div`
  display: flex;
`;

const StyledCheckboxInput = styled.input`
  margin-right: ${({ theme }) => theme.mainSpacing};
  margin-top: 0;
  margin-left: 0;
`;

const StyledFormField = styled.div<StyledFormFieldProps>`
  margin-bottom: ${({ mb0 }) => (mb0 ? '0;' : '15px;')};
  position: relative;
  ${({ draggableInput, theme }) =>
    draggableInput &&
    css`
      border: dashed 4px ${theme.colors.colorPrimary};
      display: flex;
      align-items: center;
      min-height: 60px;
      padding: calc(${theme.mainSpacing} * 2);

      input {
        opacity: 0;
        position: absolute;
      }
    `}
  ${({ theme, checkbox, textarea, customColor, icon }) => {
    const { mainSpacing, borderRadius, transitionTime, fontPrimary } = theme;
    const { colorDark, colorInput, colorGray, colorPrimary, colorDanger } = theme.colors;
    return theme.mixins.formInput({
      colorDark,
      colorInput,
      colorGray,
      colorPrimary,
      colorDanger,
      mainSpacing,
      fontPrimary,
      borderRadius,
      transitionTime,
      checkbox,
      textarea,
      customColor,
      icon: !!icon,
    });
  }};
`;

const renderCorrectInput = (
  register: any,
  {
    type,
    isRequired,
    accept,
    name,
    id,
    value,
    max,
    placeholderText,
    multiple,
    options,
    onChange,
    onClick,
    description,
    stringSelect,
    findByName,
    deleteContent,
    editId,
    isFile,
    video,
    quizFile,
    filePath,
    customFilePath,
    readonly,
    defaultValue,
    labelText,
    icon,
    iconClick,
    draggableInput,
    min,
    checked,
  }: Props,
) => {
  switch (type) {
    case 'select':
      return (
        <StyledSelect
          placeholder={placeholderText}
          multiple={multiple}
          name={name}
          size={multiple ? options?.length : 0}
          id={id || name || value}
          ref={registerRef(register, isRequired)}
          onClick={onClick}
          onChange={onChange}
          required={isRequired}
          defaultValue="Wybierz"
          disabled={readonly}
        >
          <option disabled value={defaultValue}>
            {__('application.choose')}
          </option>
          {options &&
            options.length > 0 &&
            options.map((singleOption: any) => (
              <option
                key={stringSelect ? singleOption : singleOption?._id || singleOption?.text}
                value={
                  stringSelect
                    ? singleOption
                    : singleOption?._id || singleOption?.name || singleOption?.value
                }
              >
                {stringSelect ? singleOption : singleOption?.text}
              </option>
            ))}
        </StyledSelect>
      );
    case 'checkbox':
      // for multiple chekboxes with same name in one page
      const random = new Date().getTime();
      return (
        <FlexContainer>
          <StyledCheckboxInput
            checked={checked}
            onChange={onChange}
            onClick={onClick}
            multiple={multiple}
            type={type}
            name={name}
            value={value}
            id={`${id || name}${random}`}
            ref={registerRef(register, isRequired)}
            disabled={readonly}
          />
          {labelText && (
            <label htmlFor={`${name}${random}`}>
              {labelText}
              {isRequired && ' *'}
            </label>
          )}
          {/* <ChecboxMark /> */}
        </FlexContainer>
      );
    case 'textarea':
      return (
        <textarea
          onChange={onChange}
          onClick={onClick}
          name={name}
          maxLength={max}
          id={id || name}
          ref={registerRef(register, isRequired)}
          placeholder={placeholderText}
          disabled={readonly}
          value={value}
          defaultValue={defaultValue}
        >
          {value}
        </textarea>
      );
    case 'file':
      return (
        <>
          <FilePreview
            name={name}
            deleteContent={deleteContent}
            findByName={findByName}
            customFilePath={customFilePath}
            filePath={filePath}
            video={video}
            quizFile={quizFile}
            editId={editId}
          />
          <StyledFile
            isFile={isFile}
            onChange={onChange}
            onClick={onClick}
            multiple={multiple}
            type={type}
            name={name}
            id={id || name}
            ref={registerRef(register, isRequired)}
            placeholder={placeholderText}
            accept={accept}
            disabled={readonly}
            onDrop={(e: any) => draggableInput && onChange && onChange(e)}
          />
        </>
      );
    default:
      return (
        <FlexContainer>
          <input
            onChange={onChange}
            onClick={onClick}
            multiple={multiple}
            type={type}
            name={name}
            id={id || name}
            ref={registerRef(register, isRequired)}
            placeholder={placeholderText}
            accept={accept}
            disabled={readonly}
            value={value}
            min={min}
            defaultValue={defaultValue}
            maxLength={max}
          />
          {icon && (
            <InputIcon onClick={iconClick} type="button">
              <FontAwesomeIcon icon={icon} />
            </InputIcon>
          )}
        </FlexContainer>
      );
  }
};

export const FormField: FC<Props> = ({
  type,
  labelText,
  isRequired,
  accept,
  name,
  id,
  editId,
  isFile,
  quizFile,
  video,
  placeholderText,
  field,
  children,
  multiple,
  options,
  onChange,
  onClick,
  description,
  stringSelect,
  filePath,
  customFilePath,
  findByName,
  deleteContent,
  defaultValue,
  readonly,
  icon,
  iconClick,
  mb0,
  value,
  draggableInput,
  min,
  checked,
  max,
}) => {
  const { register, errors } = useFormContext();

  const error = errors[name]?.message ?? '';

  return (
    <StyledFormField
      icon={icon}
      customColor={type === 'color'}
      checkbox={type === 'checkbox'}
      textarea={type === 'textarea'}
      draggableInput={draggableInput}
      mb0={mb0}
    >
      <StyledWrapper>
        {labelText && type !== 'checkbox' && (
          <InputLabel name={name} id={id} required={isRequired} text={labelText} />
        )}
        {children}
        {React.isValidElement(field) ? (
          field
        ) : (
          <>
            {renderCorrectInput(register, {
              type,
              isRequired,
              accept,
              name,
              editId,
              isFile,
              quizFile,
              video,
              id,
              placeholderText,
              multiple,
              options,
              onClick,
              onChange,
              description,
              stringSelect,
              filePath,
              customFilePath,
              findByName,
              deleteContent,
              defaultValue,
              readonly,
              labelText,
              max,
              icon,
              iconClick,
              value,
              draggableInput,
              min,
              checked,
            })}
            <StyledFormFieldError>{error}</StyledFormFieldError>
          </>
        )}
      </StyledWrapper>
    </StyledFormField>
  );
};
