import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalField, AdditionalFieldReq } from 'blocal-types';
import { apiService } from '../services/apiService';


// eslint-disable-next-line no-return-await
interface GetAdditionalFields {
  formId: string;
  tenantId?: string;
}

export const getAdditionalFields = createAsyncThunk(
  'form/get-additional-fields',
  async ({ formId }: GetAdditionalFields) => await apiService(`form/get-additional-fields/${formId}`, 'GET'),
);

export const customFormSchema = createSlice({
  name: 'customFormSchema',
  initialState: [] as AdditionalField[],
  reducers: {
    createAdditionalFields:  (state, action: PayloadAction<AdditionalFieldReq>) => {
      const { label, type, name, isRequired, placeholder, conditionalOptions, _id, groupBy } = action.payload;
      let formSelectOptions;

      if (type === 'select' && action.payload.selectOptions) {
        const { selectOptions } = action.payload;
        formSelectOptions = selectOptions.split(';');
      };

      const conditionalFieldOptions = {
        fieldName: conditionalOptions?.fieldName?.value,
        fieldValue: conditionalOptions?.fieldValue
      };

      const newField = {
        _id,
        label,
        type,
        name,
        formSelectOptions,
        isRequired,
        placeholder,
        conditionalOptions: conditionalFieldOptions,
        groupBy
      }

      state.push(newField);
    },
    updateAdditionalField:  (state, action: PayloadAction<AdditionalFieldReq>) => {
      const { label, type, name, isRequired, placeholder, conditionalOptions, _id, groupBy } = action.payload;
      let formSelectOptions;

      if (type === 'select' && action.payload.selectOptions) {
        const { selectOptions } = action.payload;
        formSelectOptions = selectOptions.split(';');
      };

      const conditionalFieldOptions = {
        fieldName: conditionalOptions?.fieldName?.value,
        fieldValue: conditionalOptions?.fieldValue
      };

      const field = {
        _id,
        label,
        type,
        name,
        formSelectOptions,
        isRequired,
        placeholder,
        conditionalOptions: conditionalFieldOptions,
        groupBy
      }

      const fieldIndex = state.findIndex((oldField) => oldField._id === field._id);

      state[fieldIndex] = field;
    },
    removeAdditionalField: (state, action: PayloadAction<number>) => {
      return state.filter((field, index) => index !== action.payload);
    },
    clearFormSchema: (state) => {
      return [];
    },
  },
  extraReducers: {
    [getAdditionalFields.fulfilled as any]: (state, action: PayloadAction<any>) => {
      if (action.payload?.length > 0) {
        const { additionalFields } = action.payload[0];
        additionalFields.forEach((field: any) => {
          state.push(field);
        });
      }
    },
  },
});

export const { createAdditionalFields, removeAdditionalField, clearFormSchema, updateAdditionalField } = customFormSchema.actions;

export default customFormSchema.reducer;
