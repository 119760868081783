import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import {
  space,
  typography,
  color,
  flexbox,
  position,
  layout,
  border,
  TypographyProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
  PositionProps,
  BorderProps,
  FlexboxProps,
} from 'styled-system';

interface StyledWrapperProps
  extends FlexboxProps,
    TypographyProps,
    BorderProps,
    ColorProps,
    SpaceProps,
    LayoutProps,
    PositionProps {
  withShadow?: boolean;
  configBorderRadius?: boolean;
  as?: 'div' | 'button';
}

const StyledWrapper = styled.div<StyledWrapperProps>`
  ${color}
  ${space}
  ${border}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}

  ${({ withShadow }) =>
    withShadow &&
    css`
      box-shadow: ${({ theme }) => `0 0 calc((${theme.mainSpacing}) * 1.5) calc((${theme.mainSpacing})/5) rgba(0, 0, 0, 0.2)`};
    `}
  ${({ configBorderRadius }) =>
    configBorderRadius &&
    css`
      border-radius: ${({ theme }) => `${theme.borderRadius}px` ?? 'unset'};
    `}
`;

interface Props extends StyledWrapperProps, React.DOMAttributes<HTMLElement> {
  to?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const Wrapper: FC<Props> = ({ color, children, to, as, configBorderRadius, ...props }) => (
  <StyledWrapper as={as ?? 'div'} configBorderRadius={configBorderRadius} {...props}>
    {to && <Link to={to}>{children}</Link>}
    {children}
  </StyledWrapper>
);
