import { UserRole } from 'blocal-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

interface Props {
  platformId: string;
}

// eslint-disable-next-line import/prefer-default-export
export const usePlatform = () => {
  const platformId = useSelector((state: RootState) => state.platformConfig?.config?._id);
  const selectedPlaformId = useSelector((state: RootState) => state?.selectedTenantAndModules?.tenant?.value);
  const user = useSelector((state: RootState) => state?.user?.details);
  const isSuperAdmin = user?.role === UserRole.SuperAdmin;

  const [id, setId] = useState('');

  const setCorrectId = useCallback(() => {
    if (isSuperAdmin && selectedPlaformId) {
      setId(selectedPlaformId);
    } else setId(platformId);
  }, [platformId, selectedPlaformId, user]);

  useEffect(() => {
    setCorrectId();
  }, [platformId, selectedPlaformId]);

  return {
    platformId: id,
    tenantQuery: `?tenantId=${id}`,
  };
};
