import { QuizQuestion } from 'blocal-types';
import React from 'react';
import { useQuiz } from './useQuiz';

type Dispatch = {
  setSurvey: React.Dispatch<React.SetStateAction<boolean>>;
  runQuiz: () => void;
  handleSurveySubmit: () => void;
};

export type QuizState = {
  quizId: string;
  loading: boolean;
  checkedAnswers: number[];
  correctAnswers: string[];
  getAnswer: (answer: number) => void;
  submitQuiz: (actionType: 'submit' | 'next' | 'finish') => void;
  isLastQuestionRunning: () => boolean;
  checkAnswer: (quizId: string, questionId: string, answersIds: string[], survey?: boolean, data?: { content?: string, answerId?: string }, isQuestionOpen?: boolean) => void;
  question: QuizQuestion | null;
  survey: boolean;
}

export const QuizContext = React.createContext<QuizState | null>(null);
export const QuizDispatchContext = React.createContext<Dispatch | undefined>(undefined);

type QuizProviderProps = { children: React.ReactNode };

const QuizProvider = ({ children }: QuizProviderProps) => {
  const {
    quizId,
    survey,
    runQuiz,
    checkAnswer,
    loading,
    isLastQuestionRunning,
    question,
    getAnswer,
    submitQuiz,
    checkedAnswers,
    correctAnswers,
    setSurvey,
    handleSurveySubmit,
  } = useQuiz();

  return (
    <QuizContext.Provider 
      value={{ quizId, checkAnswer, loading, isLastQuestionRunning, question, getAnswer, submitQuiz, checkedAnswers, correctAnswers, survey }}
    >
      <QuizDispatchContext.Provider
        value={{ setSurvey, runQuiz, handleSurveySubmit }}
      >
        {children}
      </QuizDispatchContext.Provider>
    </QuizContext.Provider>
  );

};

export { QuizProvider };
