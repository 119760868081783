import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuizQuestion, QuizRes } from 'blocal-types';
import { Question } from '../types/addQuiz';
import { v4 as uuid } from 'uuid';

const emptyAnswers = () => [
  {
    _id: uuid(),
  },
  {
    _id: uuid(),
  },
  {
    _id: uuid(),
  },
  {
    _id: uuid(),
  },
];

export interface QuizState {
  survey: boolean;
  quiz: QuizRes | null;
  selectedQuestion: Question | null;
  newQuestionId: string | null;
  isNewQuestionInProgress: boolean;
}

const initialState: QuizState = {
  survey: false,
  quiz: null,
  newQuestionId: null,
  selectedQuestion: null,
  isNewQuestionInProgress: false,
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuiz: (state, action: PayloadAction<QuizRes | null>) => {
      state.quiz = action.payload
    },
    setNewQuestionId: (state, action: PayloadAction<string | null>) => {
      state.newQuestionId = action.payload
    },
    setSurvey: (state, action: PayloadAction<boolean>) => {
      state.survey = action.payload
    },
    setSelectedQuestion: (state, action: PayloadAction<Question | null>) => {
      state.selectedQuestion = action.payload
    },
    setSelectedQuestionAnswer: (state, action: PayloadAction<number>) => {
      if (state?.selectedQuestion) {
        const updatedQuestionState = {
          ...state?.selectedQuestion,
          answer: state?.selectedQuestion?.answer ? state?.selectedQuestion?.answer.map((answer, i) => action.payload === i && state.selectedQuestion?.answer ? {...answer, isCorrect: !state.selectedQuestion?.answer[action.payload]?.isCorrect} : answer) : null
        };
        state.selectedQuestion = updatedQuestionState;
      }
    },
    setIsNewQuestionInProgress: (state, action: PayloadAction<boolean>) => {
      state.isNewQuestionInProgress = action.payload
    },
    setSelectedQuestionForm: (state, action: PayloadAction<{ id: string, question?: QuizQuestion, }>) => {
      const { question, id } = action.payload;
      state.selectedQuestion = {
        question: question?.question ?? null,
        answer: question?.answer ?? emptyAnswers(),
        questionPictureFn: question?.questionFn ? question?.questionFn : null,
        videoFn: question?.videoFn,
        isPhoto: question?.questionFn ? true : false,
        isVideo: question?.videoFn ? true : false,
        isPhotoForAnswers: question?.answer && question?.answer[0]?.answerFn ? true : false,
        isQuestionOpen: question && !question?.answer && !question?.isQuestionWithScale ? true : false,
        isQuestionWithScale: question?.isQuestionWithScale ? true : false,
        _id: question?._id ?? id,
      }
    },
    clearQuizState: (state) => {
      state.quiz = null;
      state.selectedQuestion = null;
      state.isNewQuestionInProgress = false;
      state.newQuestionId = null;
      state.survey = false;
    }
  }, 
});

export const { setSelectedQuestion, setSelectedQuestionAnswer, setQuiz, setSurvey, setIsNewQuestionInProgress, clearQuizState, setNewQuestionId, setSelectedQuestionForm } = quizSlice.actions;

export default quizSlice.reducer;
