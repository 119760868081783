import React, { FC, useContext, useEffect } from 'react';
import {
  QuizProvider,
  QuizContext,
  QuizDispatchContext,
} from '../../../hooks/quizzes/QuizProvider';
import { Button, QuestionsContent, Spinner } from '..';
import { ButtonsContainer } from '../../Layout';

interface QuizOnRunProps {
  surveyType?: boolean;
}

const QuizOnRun: FC<QuizOnRunProps> = ({ surveyType }) => {
  const state = useContext(QuizContext);
  const dispatch = useContext(QuizDispatchContext);

  useEffect(() => {
    dispatch?.runQuiz();
    if (surveyType) dispatch?.setSurvey(true);
  }, []);

  if (!state || !dispatch) return <Spinner />;

  const { handleSurveySubmit } = dispatch;
  const { survey, checkedAnswers, correctAnswers, submitQuiz, isLastQuestionRunning } = state;
  let button = null;
  if (survey) {
    button = (
      <Button
        onClick={handleSurveySubmit}
        text={!isLastQuestionRunning() ? 'quiz.nextQuestion' : 'survey.end'}
      />
    );
  } else {
    button = (
      <>
        {correctAnswers.length === 0 ? (
          <Button onClick={() => submitQuiz('submit')} text="application.submit" />
          ) : !isLastQuestionRunning() ? (
          <Button onClick={() => submitQuiz('next')} text="quiz.nextQuestion" />
          ) : <Button onClick={() => submitQuiz('finish')} text="quiz.finishQuiz" />
        }
      </>
    );
  }
  return (
    <>
      <QuestionsContent />
      {checkedAnswers.length > 0 && <ButtonsContainer>{button}</ButtonsContainer>}
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const QuizOnRunWrapper: FC<QuizOnRunProps> = ({ surveyType }) => (
  <QuizProvider>
    <QuizOnRun surveyType={surveyType} />
  </QuizProvider>
);
