import React, { FC, useState, useEffect, Suspense } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { WorkBoxProvider } from 'react-workbox';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import { axios401Interceptor } from './config/axios';
import {
  OnlineContent,
  OfflineContent,
  UpdateInfoBar,
  Spinner,
  AppWrapper,
  Header,
  Modal,
} from './components/Common';
import { NotificationContainer } from './components/Notifications';
import AppOffline from './components/AppOffline';
import { i18n } from './helpers/i18n';
import { persistor, store } from './config/store';
import RouteUnauthorized from './components/Routes/RouteUnauthorized';
import { scrollTop } from './utils/scroll-top';
import setWindowState from './utils/windowState';
import WelcomePageRedirect from './components/WelcomePageRedirect';
import RegisterInvitationRedirect from './components/RegisterInvitationRedirect';
import { HelmetConfig } from './config/HelmetConfig';
import { PlatformNotActive } from './views/PlatformNotActive';
import { GlobalBorderRadius, GlobalFont, GlobalTypopgrahy } from './config/globalStyles';
import { RootState } from './reducers';
import BannerIos from './components/BanneriOS';
import BannerAndroid from './components/BannerAndroid';


export const history = createBrowserHistory({ basename: '/' });

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const ContactPage = React.lazy(() => import('./views/ContactPage'));
const Register = React.lazy(() => import('./views/Register'));
const Login = React.lazy(() => import('./views/Login'));
const FAQ = React.lazy(() => import('./views/FAQ'));
const Rules = React.lazy(() => import('./views/Rules'));
const WelcomePage = React.lazy(() => import('./views/WelcomePage'));
const Home = React.lazy(() => import('./views/Home'));
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'));
const Page404 = React.lazy(() => import('./views/Page404'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));



history.listen((location) => scrollTop());

const App: FC = () => {
  const [loading, setLoading] = useState(true);

  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  axios401Interceptor();

  useEffect(() => {
    const lang = store.getState().lang.current;
    i18n.load(lang);
    checkI18nLoaded();
  }, []);

  return (
    <WorkBoxProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <OnlineContent>
            <UpdateInfoBar />
            <NotificationContainer />
            <AppWrapper>
              <AppGlobalStyles />
              <HelmetConfig />
              <Router history={history}>
                <Header />
                <Suspense fallback={<Spinner overlay />}>
                  <Switch>
                    <RouteUnauthorized exact path="/" component={Home} />
                    <RouteUnauthorized exact path="/login" component={Login} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route exact path="/rules" component={Rules} />
                    <RouteUnauthorized exact path="/register" component={Register} />
                    <Route path="/w/:userId/:updateToken" component={WelcomePageRedirect} />
                    <Route path="/welcome/first-login/:userId/:updateToken" component={WelcomePage} />
                    <Route path="/r/:tokenId" component={RegisterInvitationRedirect} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/404" component={Page404} />
                    <Route exact path="/not-active" component={PlatformNotActive} />
                    <Route path="/restore-pass" component={ResetPassword} />
                    <Route exact path="/contact-page" component={ContactPage} />
                    <Route exact path="/faq" component={FAQ} />
                    <Route render={() => <Redirect to="/404" />} />
                  </Switch>
                  <BannerIos />
                  <BannerAndroid />
                </Suspense>
              </Router>
              <Modal />
            </AppWrapper>
          </OnlineContent>
          <OfflineContent>
            <AppOffline />
          </OfflineContent>
        </PersistGate>
      </Provider>
    </WorkBoxProvider>
  );
};

const AppGlobalStyles = () => {
  const platform = useSelector((state: RootState) => state?.platformConfig);

  if (platform?.config?._id) {
    const { config, borderRadius } = platform;
    return (
      <>
        <GlobalFont _id={config?._id} />
        <GlobalTypopgrahy colorPrimary={config.colorPrimary} />
        <GlobalBorderRadius borderRadius={borderRadius} />
      </>
    );
  } else return null;
};

setWindowState();

export default App;
