/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArticleRes } from 'blocal-types';
import styled, { css } from 'styled-components';
import { useListArticle } from '../../../hooks/articles/useListArticle';
import { Spinner, Image } from '../../Common';
import { Row, Column } from '../../Layout';
import StyledColumn from '../HomeNewsDesktop/StyledColumn';
import { gtMedia } from '../../../config/config';
import ApiService from '../../../services/api-service';
import { ArticleTitle } from '../../Styled/ArticleTitle';
import { RoundedWithShadow } from '../../Styled/CarouselItem';

interface StyledLinkProps {
  main?: boolean;
  side?: boolean;
  $newsBox?: boolean;
  $last?: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  height: 175px;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;

  ${gtMedia('lg')} {
    height: 225px;
  }

  ${({ main }) =>
    main &&
    css`
      height: 365px;
      margin-bottom: 0;
      ${gtMedia('lg')} {
        height: 465px;
      }
    `}
  ${({ side }) =>
    side &&
    css`
      padding: ${({ theme }) => `0 (${theme.mainSpacing} * 0.5)`};
    `}
  ${({ $newsBox }) =>
    $newsBox &&
    css`
      margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
    `}
  ${({ $last }) =>
    $last &&
    css`
      margin-bottom: 0;
    `}
`;

interface Props {
  carouselItems?: boolean | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const NewsArticles: FC<Props> = ({ carouselItems }) => {
  const { articles, loading, isNewsSection } = useListArticle();
  useEffect(() => {
    isNewsSection();
  }, []);

  if (loading) return <Spinner />;

  if (articles.length) {
    if (carouselItems) {
      return (
        <>
          {articles.map((article: ArticleRes) => (
            <RoundedWithShadow key={article._id}>
              <Link to={`/dashboard/articles/view/${article._id}`}>
                <Image src={`${ApiService.url}article/image/articleFn/${article._id}`} />
                <div className="title">{article.title}</div>
              </Link>
            </RoundedWithShadow>
          ))}
        </>
      );
    } else {
      return (
        <StyledColumn side width={[1, 1 / 3]}>
          <Row>
            {articles.map((singleBox, index) => (
              <Column width={1} key={singleBox._id}>
                <StyledLink
                  $newsBox
                  $last={index !== 0}
                  to={`/dashboard/articles/view/${singleBox._id}`}
                >
                  <RoundedWithShadow>
                    <Image src={`${ApiService.url}article/image/articleFn/${singleBox._id}`} />
                    <ArticleTitle title={singleBox.title} />
                  </RoundedWithShadow>
                </StyledLink>
              </Column>
            ))}
          </Row>
        </StyledColumn>
      );
    }
  } else {
    return null;
  }
};
