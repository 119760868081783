import React, { FC } from 'react';
import { AdditionalField } from 'blocal-types';
import { FormField } from '../FormField';
import { Column } from '../../Layout';

const defaultRegisterFields: AdditionalField[] = [
  {
    _id: '0',
    type: 'text',
    label: 'Imię',
    isRequired: true,
    name: 'firstName',
    placeholder: 'Jan',
  },
  {
    _id: '1',
    type: 'text',
    label: 'Nazwisko',
    isRequired: true,
    name: 'lastName',
    placeholder: 'Kowalski',
  },
  {
    _id: '2',
    type: 'text',
    label: 'Adres e-mail',
    isRequired: true,
    name: 'email',
    placeholder: 'example@example.com',
  },
  {
    _id: '3',
    type: 'text',
    label: 'Numer telefonu',
    isRequired: true,
    name: 'tel',
    placeholder: '+48112321444',
  },
];

interface Props {
  readonly?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const RegisterFields: FC<Props> = ({ readonly }) => (
  <>
    {defaultRegisterFields.map((defaultField) => {
      const { type, label, isRequired, name, placeholder } = defaultField;
      return (
        <Column width={[1, null, null, 1 / 2]} key={name}>
          <FormField
            type={type}
            labelText={label}
            isRequired={isRequired}
            name={name}
            placeholderText={placeholder}
            stringSelect
            readonly={readonly}
          />
        </Column>
      );
    })}
  </>
);
