import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string | React.ReactNode;
  id?: string;
  name?: string;
  required?: boolean;
}

const StyledInputLabel = styled.label`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} / 2)`};
  font-size: 1.1rem;
  font-weight: 700;
  display: block;
`;

export const InputLabel = ({ text, required, id, name }: Props) => (
  <StyledInputLabel htmlFor={id || name}>
    {text}
    {required && ' *'}
  </StyledInputLabel>
);
