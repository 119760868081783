/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArticlePlace, ArticleRes } from 'blocal-types';
import styled, { css } from 'styled-components';
import { useListArticle } from '../../../hooks/articles/useListArticle';
import { Spinner, Image } from '..';
import StyledColumn from '../HomeNewsDesktop/StyledColumn';
import { gtMedia } from '../../../config/config';
import ApiService from '../../../services/api-service';
import { ArticleTitle } from '../../Styled/ArticleTitle';
import { RoundedWithShadow } from '../../Styled/CarouselItem';

interface StyledLinkProps {
  main?: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  height: 175px;
  position: relative;
  display: block;
  cursor: pointer;
  height: 365px;
  margin-bottom: 0;
  ${gtMedia('lg')} {
    height: 465px;
  }

  ${({ main }) =>
    main &&
    css`
      height: 365px;
      margin-bottom: 0;
      ${gtMedia('lg')} {
        height: 465px;
      }
    `}
`;

interface Props {
  carouserItem?: boolean | undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const PromotedArticle: FC<Props> = ({ carouserItem }) => {
  const { articles, loading, getArticles } = useListArticle();
  useEffect(() => {
    getArticles(ArticlePlace.Promoted, 1);
  }, []);

  if (loading) return <Spinner />;

  if (articles.length > 0) {
    if (carouserItem) {
      return (
        <>
          {articles.map((article: ArticleRes) => (
            <RoundedWithShadow key={article._id}>
              <Link to={`/dashboard/articles/view/${article._id}`}>
                <Image
                  src={`${ApiService.url}article/image/articleFn/${article._id}`}
                  alt={article.title}
                />
                <div className="title">{article.title}</div>
              </Link>
            </RoundedWithShadow>
          ))}
        </>
      );
    } else {
      return (
        <>
          {articles.map((article: ArticleRes) => (
            <StyledColumn key={article._id} side width={[1, 2 / 3]}>
              <RoundedWithShadow>
                <StyledLink to={`/dashboard/articles/view/${article._id}`}>
                  <Image src={`${ApiService.url}article/image/articleFn/${article._id}`} />
                  <ArticleTitle bigFont title={article.title} />
                </StyledLink>
              </RoundedWithShadow>
            </StyledColumn>
          ))}
        </>
      );
    }
  } else {
    return null;
  }
};
