import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiErrorCode,
  DefaultUserFields,
  GroupFormRes,
  GroupFormResSelect,
  GroupRes,
  SingleGroupRes,
  ValueAndLabelObj,
} from 'blocal-types';
import { errorNotification } from '../actions/notifications';
import { history } from '../App';
import { store } from '../config/store';
import { apiService } from '../services/apiService';

export type FieldCondition = 'and' | 'or';

export type Operator = '$eq' | '$lt' | '$gt';

export type SelectedField = GroupRes | DefaultUserFields;

export type ConditionOption = {
  value: Operator;
  label: string;
  condition: FieldCondition;
};

export interface Index {
  index: number;
}

export interface ConditionFieldPayload extends Index {
  rules: {
    operatorOptions: ConditionOption;
    value: string | number | ValueAndLabelObj;
  }[];
}

export interface CreateGroupsState {
  name: string;
  fieldsAndGroups: GroupFormRes[] | null;
  rules:
    | {
        field: SelectedField | null;
        condition?: FieldCondition;
        rules?:
          | {
              value: string | number | ValueAndLabelObj;
              operatorOptions: ConditionOption;
            }[]
          | null;
      }[]
    | null;
}

export const initState = {
  name: '',
  fieldsAndGroups: null,
  rules: null,
} as CreateGroupsState;

export const getGroupsAndFields = createAsyncThunk('getGroups', async () =>
  apiService(
    'group/all',
    'GET',
    undefined,
    (err) => {
      const { response } = err;
      if (response.data.error_code === ApiErrorCode.TenantIsDeactivated) {
        history.push('/not-active');
      } else {
        store.dispatch(errorNotification(response.data.error_code));
      }
    },
    true,
  ),
);

export const getGroupDetails = createAsyncThunk('getGroupDetails', async (id: string) =>
  apiService(
    `group/one/${id}`,
    'GET',
    undefined,
    (err) => {
      const { response } = err;
      if (response.data.error_code === ApiErrorCode.TenantIsDeactivated) {
        history.push('/not-active');
      } else {
        store.dispatch(errorNotification(response.data.error_code));
      }
    },
    true,
  ),
);

export const groups = createSlice({
  name: 'lang',
  initialState: initState,
  reducers: {
    selectField: (state, action: PayloadAction<{ field: SelectedField; index: number }>) => {
      if (state.rules && state?.rules[action.payload.index]) {
        return {
          ...state,
          rules: state.rules.map((condition, i) =>
            i === action.payload.index
              ? { ...condition, rules: null, field: action.payload.field }
              : condition,
          ),
        };
      }
      return {
        ...state,
        rules: state.rules
          ? [...state.rules, { field: action.payload.field }]
          : [{ field: action.payload.field }],
      };
    },
    setOperator: (state, action: PayloadAction<{ condition: FieldCondition } & Index>) => {
      if (state.rules) {
        state.rules.splice(action.payload.index, 0, {
          field: null,
          rules: null,
          condition: action.payload.condition,
        });
      } else {
        state.rules = [{ field: null, rules: null, condition: action.payload.condition }];
      }
    },
    setFieldCondition: (state, action: PayloadAction<ConditionFieldPayload>) => {
      if (state.rules && state.rules[action.payload.index]) {
        state.rules[action.payload.index].rules = action.payload.rules;
      } else return state;
    },
    clearState: (state) => initState,
    clearRules: (state) => {
      state.rules = null;
    },
    deleteField: (state, action: PayloadAction<Index>) => {
      if (state.rules && state.rules[action.payload.index]) {
        state.rules.splice(action.payload.index, 1);
      } else return state;
    },
  },
  extraReducers: {
    [getGroupsAndFields.fulfilled as any]: (state, action: PayloadAction<GroupFormRes[]>) => {
      state.fieldsAndGroups = [];

      /**
       * Mapping field from BE and remove duplicates.
       * If mapped field has type select, then take and check whether field with this type and name exist,
       * if exist findIndex and add options from field. If not push like regular field
       */

      action.payload.forEach((field: GroupFormRes) => {
        if (field?.type === 'select') {
          const existedFieldIndex =
            state?.fieldsAndGroups && state.fieldsAndGroups.findIndex((f) => f.name === field.name);

          if (state?.fieldsAndGroups && existedFieldIndex && existedFieldIndex >= 0) {
            const existedField = state.fieldsAndGroups[existedFieldIndex] as GroupFormResSelect;
            existedField.formSelectOptions = [
              ...existedField.formSelectOptions,
              ...field.formSelectOptions,
            ];
          } else {
            state.fieldsAndGroups!.push(field);
          }
        } else {
          state.fieldsAndGroups!.push(field);
        }
      });
    },
    [getGroupDetails.fulfilled as any]: (state, action: PayloadAction<SingleGroupRes>) => {
      state.rules = action.payload.originalCondition.rules;
      state.name = action.payload.originalCondition.name;
    },
  },
});

export const {
  selectField,
  setOperator,
  setFieldCondition,
  clearState,
  clearRules,
  deleteField,
} = groups.actions;

export default groups.reducer;
