import { Permission } from 'blocal-types';
import { __ } from './i18n';

// eslint-disable-next-line import/prefer-default-export
export const getLabelForPermission = (perm: Permission): string => {
  switch (perm) {
    case Permission.AddQuiz:
      return __('permissions.addQuiz');
    case Permission.DeleteQuiz:
      return __('permissions.deleteQuiz');
    case Permission.EditQuiz:
      return __('permissions.editQuiz');
    case Permission.AddArticle:
      return __('permissions.addArticle');
    case Permission.DeleteArticle:
      return __('permissions.deleteArticle');
    case Permission.EditArticle:
      return __('permissions.editArticle');
    case Permission.HideArticle:
      return __('permissions.hideArticle');
    case Permission.ModerateComments:
      return __('permissions.moderateComments')
    case Permission.AddContest:
      return __('permissions.addContest');
    case Permission.DeleteContest:
      return __('permissions.deleteContest');
    case Permission.HideContest:
      return __('permissions.hideContest');
    case Permission.ScanQRCode:
      return __('permissions.scanQrCode');
    case Permission.ShowContestParticipants:
      return __('permissions.showContestParticipants');
    case Permission.AcceptUser:
      return __('permissions.acceptUser');
    case Permission.RejectUser:
      return __('permissions.rejectUser');
    case Permission.BlockUserActions:
      return __('permissions.blockUserActions');
    case Permission.ShowUser:
      return __('permissions.showUser');
    case Permission.PwdReset:
      return __('permissions.pwdReset');
    case Permission.AddSuperuser:
      return __('permissions.addSuperuser');
    case Permission.AssignSuperuser:
      return __('permissions.assignSuperuser');
    case Permission.DeleteParticipant:
      return __('permissions.deleteParticipant');
    case Permission.EditContest:
      return __('permissions.editContest');
    case Permission.CancelContest:
      return __('permissions.cancelContest');
    case Permission.FinishContest:
      return __('permissions.finishContest');
    case Permission.BlockContest:
      return __('permissions.blockContest');
    case Permission.ExportUsers:
      return __('permissions.exportUsers');
    case Permission.ExportParticipants:
      return __('permissions.exportParticipants');
    case Permission.AddParticipants:
      return __('permissions.addParticipants');
    case Permission.UserAcceptance:
      return __('permissions.userAcceptance');
    case Permission.ImportUsers:
      return __('permissions.importUsers');
    case Permission.ChangeRole:
      return __('permissions.changeRole');
    case Permission.CreateCommunication:
      return __('permissions.messagesSending');
    case Permission.ViewCommunication:
      return __('permissions.messagesView');
    case Permission.DeleteCommunication:
      return __('permissions.messagesDeleting');
    case Permission.CustomBanners:
      return __('permissions.customBanners');
    case Permission.CustomTiles:
      return __('permissions.customTiles');
    case Permission.Attachments:
      return __('permissions.attachment');
    case Permission.CreateGroup:
      return __('permissions.createGroup');
    case Permission.ExportGroupUsers:
      return __('permissions.exportGroupUsers');
    case Permission.DeleteGroup:
      return __('permissions.deleteGroup');
    case Permission.PresentationManagement:
      return __('permissions.presentationManagement');
    default:
      return __(`permissions.${perm}`);
  }
};
