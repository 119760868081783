import React, { FC, useContext } from 'react';
import Select from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';
import { FormField, Spinner } from '..';
import { Column } from '../../Layout';
import { inputSelectStyles } from '../../../helpers/additionalStyles';
import { ModeratorManage, ModeratorManageDispatch } from '../../../hooks/moderator/ModeratorManageProvider';
import { __ } from '../../../helpers/i18n';

// eslint-disable-next-line import/prefer-default-export
export const ModeratorRoleSetting: FC = () => {
  const methods = useFormContext();
  const moderator = useContext(ModeratorManage);
  const dispatch = useContext(ModeratorManageDispatch);

  if (!moderator || !dispatch) return <Spinner />;

  return (
    <>
      <Column width={1}>
        <FormField
          labelText="Wybierz moduły zarządzane przez użytkownika o roli: Moderator"
          type="select"
          isRequired
          name="moderatorModules"
          field={
            <Controller
              isMulti
              name="moderatorModules"
              control={methods.control}
              defaultValue={null}
              render={({ value, name }) => (
                <Select
                  isMulti
                  options={moderator?.state.allModules ? moderator?.state.allModules : []}
                  styles={inputSelectStyles()}
                  onChange={(e: any) => moderator.handleModuleChange(e)}
                  name={name}
                  placeholder={__('application.choose')}
                  noOptionsMessage={() => __('application.noOptions')}
                  value={moderator?.state?.chosenModules}
                />
              )}
            />
          }
        />
      </Column>
      <Column width={1}>
        <FormField
          labelText="Wybierz uprawnienia użytkownika o roli: Moderator"
          type="select"
          name="moderatorPermissions"
          isRequired
          defaultValue={null}
          field={
            <Controller
              isMulti
              name="moderatorPermissions"
              control={methods.control}
              render={({ name }) => (
                <Select
                  isMulti
                  options={moderator?.state.allActions ? moderator?.state.allActions : []}
                  styles={inputSelectStyles()}
                  onChange={(e: any) => moderator.handleActionChange(e)}
                  name={name}
                  noOptionsMessage={() => __('application.noOptions')}
                  placeholder={__('application.choose')}
                  value={moderator?.state?.chosenActions}
                />
              )}
            />
          }
        />
      </Column>
    </>
  );
};
