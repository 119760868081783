import { UseFormMethods } from 'react-hook-form';
import { DefaultUserFields, LoginRes, ModPerm, Permission, SelectOption, TenantSegment } from 'blocal-types';
import { getLabelForPermission } from '../../helpers/permissionLabels';
import { MultimediaFile } from '../../types/photo';

export interface SegmentSelectOption extends SelectOption {
  modulesAction: Permission[] | null;
}
export interface ModeratorModule {
  value: string;
  modulesAction: Permission[] | null;
  label: string;
}

export interface ModeratorManageState {
  allActions: ModPerm[] | null;
  allModules: ModeratorModule[] | null;
  chosenModules: SegmentSelectOption[] | null;
  chosenActions: ModPerm[] | null;
  isLoading: boolean;
  isSubmitting: boolean;
  moderatorDetails: LoginRes | null;
}

export interface ModeratorManageReducer extends ModeratorManageState {
  state: ModeratorManageState;
  handleModuleChange: (modules: SegmentSelectOption[]) => void;
  handleActionChange: (actions: ModPerm[]) => void;
  submit: (data: DefaultUserFields, e: any, formCtx: UseFormMethods<Record<string, any>>, userImage: MultimediaFile[], newUserId?: string) => void;
}

export const initState: ModeratorManageState = {
  allActions: null,
  allModules: null,
  chosenModules: null,
  chosenActions: null,
  isLoading: false,
  isSubmitting: false,
  moderatorDetails: null,
}

export interface SetAllModules {
  type: 'setAllModules';
  payload: ModeratorModule[];
}

export interface SetModule {
  type: 'setModule';
  payload: SegmentSelectOption[];
};

export interface SetActionsForModule {
  type: 'setActionsForModule';
}

export interface SetAction {
  type: 'setAction';
  payload: ModPerm[];
}

export interface ClearModules {
  type: 'clearModules';
}

export interface ClearActions {
  type: 'clearActions';
}

export interface SetModeratorDetails {
  type: 'setModeratorDetails';
  payload: LoginRes;
}

export type ModeratorAction =
  | SetModule
  | SetAction
  | SetActionsForModule
  | ClearActions
  | ClearModules
  | SetModeratorDetails
  | SetAllModules;

export const moderatorManageReducer = (state: ModeratorManageState, action: ModeratorAction) => {
  switch (action.type) {
    case 'setModeratorDetails':
      return {
        ...state,
        moderatorDetails: action.payload,
      }
    case 'setAllModules': {
      return {
        ...state,
        allModules: action.payload,
      }
    }
    case 'setModule':
      return {
        ...state,
        chosenModules: action.payload ? [...action.payload] : null,
      };

    case 'setAction':
      return {
        ...state,
        chosenActions: action.payload ? [...action.payload] : null
      }

    case 'setActionsForModule':
      console.log(state);
      if (state?.chosenModules) {
        const selectedModulesWithAction = state.chosenModules.filter((module) => module.modulesAction);
        const actionsWithLabels: ModPerm[][] = selectedModulesWithAction.map((module) => (
          module.modulesAction!.map((action: Permission) => ({
            value: action,
            label: getLabelForPermission(action)
          }))
        ));

        const actionWithLabelNormalized = actionsWithLabels.flat(1);

        const isIn = (action: ModPerm) => {
          return actionWithLabelNormalized.some((item) => item.value === action.value)
        }

        return {
          ...state,
          allActions: actionWithLabelNormalized,
          chosenActions: state.chosenActions ? [...state.chosenActions.filter((action) => isIn(action))] : null
        }
      } else {
        return { ...state, allActions: null, chosenActions: null }
      }
    case 'clearActions':
      return {
        ...state,
        chosenActions: null,
        allActions: null
      }
    case 'clearModules':
      return {
        ...state,
        chosenModules: null
      }
  }
};
