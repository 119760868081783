import React, { FC } from 'react';
import { MdDelete } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { IconActionButton } from '..';

interface PreviewIconProps {
  action: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const RemoveIcon: FC<PreviewIconProps> = ({ action }) => {
  const { colors } = useTheme();
  return (
    <IconActionButton onClick={action} bgColor={colors.colorDanger} title="application.delete">
      <MdDelete size={18} color="#fff" />
    </IconActionButton>
  );
};
