import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
}

/* border: ${`1px solid ${theme.colors.colorInput}`}; */
const StyledDiv = styled.div<{ preview?: boolean }>`
  border: ${({ theme, preview }) => `${preview ? 0 : '1px'} solid ${theme.colors.colorInput}`};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-bottom: 20px;
  ${({ preview }) =>
    !preview &&
    css`
      padding: 10px 15px;
      min-height: 315px;
    `};

  h2 {
    font-size: 2.1rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  .embed-tool {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .ce-block__content,
  .ce-toolbar__content {
    max-width: 100%;
    margin: 0 auto;
  }

  .ce-block .ce-block__content {
    padding: 0;
  }
  .image-tool__caption,
  .embed-tool__caption {
    display: none;
  }
  .image-tool--withBackground .image-tool__image {
    background: #fff;
  }
  .ce-block__content,
  .ce-toolbar {
    padding: 0 25px;
  }

  .codex-editor__redactor {
    padding-bottom: 0 !important;
    margin-right: 0;
  }

  .ce-paragraph {
    font-weight: 300;
  }

  img {
    cursor: pointer;
  }

  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  h4 {
    font-size: 1.6rem;
  }

  table {
    width: 100%;

    tr {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.colorPrimary}`};
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const StyledWysiwyg = ({ children, preview }: Props) => {
  const handleClick = (event: any) => {
    if (event.target.tagName === 'IMG') {
      const { src } = event.target;
      window.open(src, '_blank');
    }
  };
  return (
    <StyledDiv onClick={handleClick} preview={preview}>
      {children}
    </StyledDiv>
  );
};
