import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { QuizStatus } from 'blocal-types';
import { useQuiz } from '../../../hooks/quizzes/useQuiz';
import { ButtonsContainer, Column } from '../../Layout';
import { Button, Text, Card, Wrapper, Spinner } from '..';
import { __ } from '../../../helpers/i18n';
import { history } from '../../../App';

interface QuestionsPreviewProps {
  survey?: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const QuestionsPreview: FC<QuestionsPreviewProps> = ({ survey }) => {
  const { quizId, quizInfo, loading, getPoints, points, getQuizInfo } = useQuiz();

  const surveyPath = (id: string) => `/dashboard/quizzes/surveys/view/${id}`;
  const quizPath = (id: string) => `/dashboard/quizzes/view/${id}`;

  useEffect(() => {
    if (quizId) getQuizInfo();
  }, []);

  let quizPreviewButton = null;

  const setQuizPreviewButton = () => {
    const present = new Date();
    const quizEndDt = quizInfo && quizInfo?.details?.endDt ? new Date(quizInfo?.details?.endDt).getTime() : new Date().getTime();
    if (quizEndDt <= present.getTime()) {
      quizPreviewButton = (
        <ButtonsContainer p="0 10px">
          <Button
            disabled
            width={1}
            text={survey ? 'survey.surveyIsInactive' : 'quiz.quizIsInactive'}
          />
        </ButtonsContainer>
      );
      return;
    }

    switch (quizInfo?.quizStatus) {
      case QuizStatus.Created:
        quizPreviewButton = (
          <ButtonsContainer p="0 10px">
            <Button
              width={1}
              onClick={() => history.push(survey ? surveyPath(quizId) : quizPath(quizId))}
              text={survey ? 'survey.fillSurvey' : 'quiz.startQuiz'}
            />
          </ButtonsContainer>
        );
        break;
      case QuizStatus.InProgress:
        quizPreviewButton = (
          <ButtonsContainer p="0 10px">
            <Button
              width={1}
              onClick={() => history.push(survey ? surveyPath(quizId) : quizPath(quizId))}
              text={survey ? 'survey.continueFillingSurvey' : 'quiz.continueQuiz'}
            />
          </ButtonsContainer>
        );
        break;
      case QuizStatus.Finished:
        quizPreviewButton = (
          <Column width={1} justifyContent="center">
            <Text textAlign="center">
              {survey ? __('survey.thanksForFillingSurvey') : __('quiz.quizResolved')}
              <br />
              {!survey && points !== null && `${__('quiz.reachedPoints')}: ${points}`}
            </Text>
          </Column>
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (quizInfo) setQuizPreviewButton();
  }, [quizInfo]);
  if (loading) {
    return <Spinner />;
  }
  if (quizInfo) setQuizPreviewButton();
  if (quizInfo?.quizStatus === QuizStatus.Finished && (!survey && points === null)) getPoints();

  console.log('info', quizInfo);

  return (
    <Wrapper maxWidth="600px" m="0 auto">
      {quizInfo && (
        <Card
          isFirst
          key={quizInfo?.details._id}
          use="quiz"
          title={quizInfo.details.title ?? ''}
          imgUrl={`quiz/image/quizPictureFn/${quizId}`}
          extraText={quizInfo?.details?.description ?? null}
          points={!survey ? (`${quizInfo?.details?.maxPoints}PKT` ?? null) : undefined}
        />
      )}
      {quizPreviewButton}
      {quizInfo?.details?.articleId && (
        <Wrapper p={1} textAlign="center">
          <Text textTransform="uppercase">{__('quiz.thisQuizIsLinkedToArticle')}</Text>
          <Link to={`/dashboard/articles/view/${quizInfo.details.articleId}`}>[{__('application.see')}]</Link>
        </Wrapper>
      )}
    </Wrapper>
  );
};
