import axios, { Method } from 'axios';
import { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { MsgResult } from '../types/msgResults';
import { handlingError } from '../helpers/handlingErrors';
import { axiosConfig } from '../config/axios';

interface Props {
  timeout?: number;
  methods?: UseFormMethods<Record<string, any>>;
}
interface UseApi {
  api: <T, K = void>({
    method,
    path,
    msgResult,
    data,
    onSuccess,
    onError,
    withoutLoading,
    withDelay,
    params,
  }: ApiProps<T, K>) => Promise<void>;
  loading: boolean;
}

export const useApi = (props?: Props): UseApi => {
  const [loading, setLoading] = useState(false);
  const delay = 500;
  const getSetTimeoutTime = (startAt: Date, withDelay?: boolean) => {
    const latency = startAt.getTime() - new Date().getTime();
    const isLatencyMoreThenDelay = latency < delay;
    return withDelay && isLatencyMoreThenDelay ? delay - latency : 0;
  };

  const api = async <T, K = void>({
    method,
    path,
    msgResult,
    data,
    onSuccess,
    onError,
    withoutLoading,
    withDelay,
    params,
  }: ApiProps<T, K>) => {
    if (!withoutLoading) {
      setLoading(true);
    }
    const startAt = new Date();
    axios(axiosConfig(path, method, data, params, props?.timeout))
      .then((res) =>
        setTimeout(() => {
          onSuccess?.(res?.data?.payload as T);
          setLoading(false);
        }, getSetTimeoutTime(startAt, withDelay)),
      )
      .catch((err) =>
        setTimeout(() => {
          setLoading(false);
          if (onError) onError(err);
          else handlingError(err?.response, props?.methods?.setError);
        }, getSetTimeoutTime(startAt, withDelay)),
      );
  };

  return { api, loading };
};

interface ApiProps<T, K = void> {
  method: Method;
  path: string;
  msgResult?: MsgResult;
  data?: K;
  onSuccess?: (res: T) => void;
  onError?: (res: any) => void;
  withoutLoading?: boolean;
  withDelay?: boolean;
  params?: string;
}
