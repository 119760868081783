import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { Button } from '..';

interface QuizButtonProps {
  isPhoto?: boolean;
  onClick?: () => void;
  checked: boolean;
  isCorrect: boolean;
  isWrong: boolean;
}

export const QuizButton: FC<QuizButtonProps> = ({ isPhoto, children, checked, onClick, isCorrect, isWrong }) => {
  const { colors } = useTheme();
  const { colorSuccess, colorDanger, colorPrimary, colorDisabled } = colors;
  const getColor = () => {
    if (isCorrect) {
      return colorSuccess;
    } 
    if (isWrong) {
      return colorDanger;
    }

    if (checked) {
      return colorPrimary;
    }
    return colorDisabled;
  }

  return (
    <Button
      width="100%"
      height={["30vw", null, null, "300px"]}
      backgroundColor="colorLight"
      color="colorDark"
      border="3px solid"
      fontSize="1.8rem"
      textAlign="center"
      overflowX="auto"
      onClick={onClick}
      borderColor={getColor()}
      padding={isPhoto ? "0" : "15px 40px"}
    >
      {children}
    </Button>
  )
};
