import React, { FC, useContext } from 'react';
import { Answer } from 'blocal-types';

import { OpenQuestionAnswer } from '../../../views/Dashboard/Quizzes/Quiz/OpenQuestionAnswer';
import { Row, Column } from '../../Layout';
import { Wrapper, Text, Image, VideoPlayer, Spinner, QuizButton } from '..';
import { apiUrl } from '../../../config/apiUrl';
import { QuizContext } from '../../../hooks/quizzes/QuizProvider';

// eslint-disable-next-line import/prefer-default-export
export const QuestionsContent: FC = () => {
  const state = useContext(QuizContext);

  if (!state) return <Spinner />;
  const { question, quizId, correctAnswers, checkedAnswers, getAnswer } = state;
  const isButtonChosen = (ansI: number) => checkedAnswers.includes(ansI) && correctAnswers.length > 0;
  const isQuestionAnswered = () => correctAnswers.length !== 0;

  return (
    <>
      <Row mb={1}>
        <Column alignContent="center" justifyContent="center" width={[1, null, null, 1/2]}>
          {question && <Text fontSize="normal">{question?.question}</Text>}
        </Column>
        {question?.questionFn && (
          <Column width={[1, null, null, 1 / 2]}>
            <Wrapper height="260px" mb={2}>
              <Image src={`${apiUrl}quiz/image/questionPictureFn/${quizId}/${question._id}`} />
            </Wrapper>
          </Column>
        )}
        {question?.videoFn && <VideoPlayer videoSrc={question.videoFn} />}
      </Row>
      <Row>
        {question?._id && (!question?.answer || question.isQuestionWithScale) && (
          <OpenQuestionAnswer questionId={question._id} />
        )}
        {!question?.isQuestionWithScale && question?.answer && question.answer.map((an: Answer, ansI: number) => (
          <Column width={[1 / 2, null, null, 1 / 4]} mb={1} key={an._id}>
            <QuizButton
              checked={checkedAnswers.includes(ansI)}
              isCorrect={isButtonChosen(ansI) && correctAnswers.includes(an._id)}
              isWrong={isButtonChosen(ansI) && !correctAnswers.includes(an._id)} 
              onClick={!isQuestionAnswered() ? () => getAnswer(ansI) : undefined}
              isPhoto={an?.answerFn ? true : false}
            >
              {an?.content && <Text color="colorDark">{an?.content}</Text>}
              {an?.answerFn && <Image src={`${apiUrl}quiz/image/answerPictureFn/${quizId}/${question._id}/${an._id}`} />}
            </QuizButton>
          </Column>
        ))}
      </Row>
    </>
  );
};
