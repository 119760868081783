import React, { FC } from 'react';
import { Text } from '..';

interface Props {
  mb?: number;
}
// eslint-disable-next-line import/prefer-default-export
export const SectionSubtitle: FC<Props> = ({ children, mb }) => (
  <Text 
    as="h3" 
    fontSize="1.5rem"
    fontWeight="bold"
    mb={mb}
    >
    {children}
  </Text>
);
