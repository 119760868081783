import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegistrationType, SMSStatRes, SelectOption, TenantSegment } from 'blocal-types';
import { apiService } from '../services/apiService';

export interface ActiveModules {
  _id: string;
  text: string;
}

export interface SelectedTenantAndActiveModules {
  tenant: SelectOption;
  activeModules: TenantSegment[] | null;
  boundType: SelectOption | null;
  infoIcon: string | null;
  registrationType: RegistrationType | null;
  registrationFields: SelectOption[] | null;
  smsStats: SMSStatRes | null;
}

export const getTenantActiveModules = createAsyncThunk(
  'getTenantActiveModules',
  async (tenantId: string) => await apiService(`tenant/active-modules/${tenantId}`, 'GET'),
);

export const selectedTenantAndActiveModules = createSlice({
  name: 'selectedTenantAndActiveModules',
  initialState: {} as SelectedTenantAndActiveModules,
  reducers: {
    setSelectedTenant: (state, action: PayloadAction<SelectOption>) => {
      state.tenant = action.payload;
    },
  },
  extraReducers: {
    [getTenantActiveModules.fulfilled as any]: (
      state,
      action: PayloadAction<{
        segments: TenantSegment[];
        boundType: SelectOption;
        infoIcon: string | null;
        registrationType: RegistrationType | null;
        registrationFields: SelectOption[] | null;
        smsStats: SMSStatRes | null;
      }>,
    ) => {
      state.activeModules = action.payload.segments;
      state.boundType = action.payload.boundType;
      state.infoIcon = action.payload.infoIcon;
      state.registrationType = action.payload.registrationType;
      state.registrationFields = action.payload.registrationFields;
      state.smsStats = action.payload.smsStats;
    },
    [getTenantActiveModules.rejected as any]: (state) => {
      state.activeModules = null;
      state.boundType = null;
      state.infoIcon = null;
      state.registrationType = null;
      state.registrationFields = null;
      state.smsStats = null;
    },
  },
});

export const { setSelectedTenant } = selectedTenantAndActiveModules.actions;

export default selectedTenantAndActiveModules.reducer;
