import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ArticleRes } from 'blocal-types';
import { isBefore } from 'date-fns';
import { SectionTitle, Alert, Button, Spinner } from '../../../../../components/Common';
import { __ } from '../../../../../helpers/i18n';
import CommentForm from './CommentForm';
import SingleComment from './SingleComment';
import { ButtonsContainer } from '../../../../../components/Layout';

const CommentsWrapper = styled.div`
  margin-top: ${({ theme }) => `calc(${theme.mainSpacing} * 3)`};
`;

interface Props {
  article: ArticleRes;
  isLoading: boolean;
  getArticle: () => void;
}

const ArticleComments: FC<Props> = ({ article, getArticle, isLoading }) => {
  const { comments, _id, disableComments } = article;
  const [offset, setOffset] = useState(5);

  return (
    <>
      <SectionTitle>{__('article.comments')}</SectionTitle>
      <CommentsWrapper>
        {isLoading && <Spinner overlay halfTransparent />}
        {disableComments ? (
          <Alert type="error" simple text="article.commentsDisabled" />
        ) : (
          <CommentForm articleId={_id} getArticle={getArticle} />
        )}
        {!comments || comments.length === 0 ? (
          <Alert type="error" simple text="article.noComments" />
        ) : (
          comments
            .sort((a, b) => (isBefore(new Date(a.date!), new Date(b.date!)) ? 1 : -1))
            .slice(0, offset)
            .map((comment) => (
              <SingleComment
                comment={comment}
                article={article}
                getArticle={getArticle}
                key={comment.id}
              />
            ))
        )}
        {comments.length > offset && (
          <ButtonsContainer justifyContent="center">
            <Button text="article.loadMoreComments" onClick={() => setOffset(offset + 5)} />
          </ButtonsContainer>
        )}
      </CommentsWrapper>
    </>
  );
};

export default ArticleComments;
