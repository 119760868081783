import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

interface StyledEventDataProps {
  date?: boolean;
  place?: boolean;
  bigFont?: boolean;
}

interface EventDataProps extends StyledEventDataProps {
  text: string;
}

const StyledEventData = styled.div<StyledEventDataProps>`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: ${({ theme }) => `calc(1 * ${theme.mainSpacing})`};
  ${({ date }) =>
    date &&
    css`
      margin-top: ${({ theme }) => `calc(.5 * ${theme.mainSpacing})`};
      font-style: italic;
      color: ${({ theme }) => theme.colors.colorTextSecondary}};
    `}
  ${({ place }) =>
    place &&
    css`
      color: ${({ theme }) => theme.colors.colorSecondary}};

    `}
  ${({ bigFont }) =>
    bigFont &&
    css`
      font-size: 1.6rem;
    `}
`;

const EventIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => `calc(.5 * ${theme.mainSpacing})`};
`;

// eslint-disable-next-line import/prefer-default-export
export const EventData: FC<EventDataProps> = ({ bigFont, place, text, date }) => (
  <StyledEventData place={place} date={date}>
    {place && <EventIcon icon={faMapMarkerAlt} />}
    {text}
  </StyledEventData>
);
