/* eslint-disable no-return-await */
/* eslint-disable no-useless-catch */
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginRes } from 'blocal-types';
import { successNotification } from '../actions/notifications';
import { history } from '../App';
import { store } from '../config/store';
import { __ } from '../helpers/i18n';
import { apiService } from '../services/apiService';
import { getCartDetails } from './cartSlice';

const redirectToLogin = () => history.push('/');

export const getUserDetails = createAsyncThunk(
  'user/details',
  async () => {
    const res: LoginRes = await apiService(
      'user/details',
      'GET',
      undefined,
      (err) => {
        store.dispatch(logout(null));
        redirectToLogin();
      },
      true,
    )
    const { cartProducts, ...user  } = res;
      store.dispatch((getCartDetails()))
    return user;
  }
);


export const refreshUserDetails = createAsyncThunk(
  'user/details',
  async () => await apiService('user/details', 'GET', undefined, undefined, true),
);

export const handleLogout:  AsyncThunk<any, void, {}> = createAsyncThunk(
  'auth/logout',
  async () => await apiService('auth/logout', 'GET', () =>
      store.dispatch(successNotification(__('application.logoutCorrectly'))),
    ),
);

export interface UserState {
  loading: boolean;
  details: LoginRes | null;
  userPoints: null | number;
  userPosition: null | number;
  pointsToTop: null | number;
  lockCart: boolean;
}

const initState: UserState = {
  loading: false,
  details: null,
  userPoints: null,
  userPosition: null,
  pointsToTop: null,
  lockCart: false,
} 

const user = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    loadingUser: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logout: (state, action: PayloadAction<null>) => {
      state.details = action.payload;
    },
    setUser: (state, action: PayloadAction<LoginRes>) => {
      state.details = action.payload;
    },
  },
  extraReducers: {
    [getUserDetails.fulfilled as any]: (state, action: PayloadAction<LoginRes>) => {
      state.details = action.payload;
    },
    [getUserDetails.rejected as any]: (state, action: PayloadAction<null>) => {
      state.details = null;
    },
    [refreshUserDetails.fulfilled as any]: (state, action: PayloadAction<LoginRes>) => {
      state.details = action.payload;
    },
    [refreshUserDetails.rejected as any]: (state, action: PayloadAction<null>) => {
      state.details = null;
    },
    [handleLogout.fulfilled as any]: (state, action: PayloadAction) => {
      state.details = null;
    },
    [handleLogout.rejected as any]: (state, action: PayloadAction) => {
      console.log('err');
    },
  },
});

export const { loadingUser, logout, setUser } = user.actions;

export default user.reducer;
