import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styled, { css } from 'styled-components';
import { ButtonsContainer } from '../../Layout';
import { Button } from '../Button';
import { InputLabel } from '../InputLabel';
import { apiUrl } from '../../../config/apiUrl';
import { __ } from '../../../helpers/i18n';
import { gtMedia } from '../../../config/config';
import Spinner from '../Spinner';

interface Props {
  onSave: (blob: Blob, url: string) => void;
  onDelete: () => void;
  desktopRatio: number;
  mobileRatio: number;
  labelText: string;
  required?: boolean;
  previewUrl?: string;
}

interface CropperStyledProps {
  mobileRatio: number;
  aspectRatio: number;
}

const StyledCropper = styled(Cropper)<CropperStyledProps>`

  margin: ${({ theme }) => `${theme.mainSpacing} auto`};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  overflow: hidden;

    ${gtMedia('md')} {
        max-width: 60vw;
    }
  .cropper-view-box {
    &:after {
      content: '';
      border: 1px solid red;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${({ mobileRatio, aspectRatio }) =>
        mobileRatio > aspectRatio
          ? css`
              width: 100%;
              height: ${100 / aspectRatio / mobileRatio}%;
            `
          : css`
              height: 100%;
              width: ${(100 / aspectRatio) * mobileRatio}%;
            `}
    }
  }
`;

const StyledImagePicker = styled.div`
  overflow: auto;
  display: block;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  padding: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  background-color: ${({ theme }) => theme.colors.colorInput};
`;

const ImagePreview = styled.img`
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  margin: ${({ theme }) => `${theme.mainSpacing} auto`};
  display: block;
  ${gtMedia('md')} {
    max-width: 60vw;
  }
`;

const FileInput = styled.label`
  border-radius: 0;
  display: block;
  cursor: pointer;
  padding: ${({ theme }) => `calc(${theme.mainSpacing} * 5)`};
  text-align: center;
  input {
    display: none;
  }
`;

export const ImagePicker: React.FC<Props> = ({
  onSave,
  onDelete,
  desktopRatio,
  mobileRatio,
  labelText,
  previewUrl,
  required,
}: Props) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(previewUrl);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState<any>();
  const [isPreview, setPreview] = useState<boolean>(false);

  useEffect(() => {
    if (previewUrl) {
      setLoading(true);
      setPreview(true);
    }
  }, []);

  const previewUnavailable = () => {
    setLoading(false);
    setPreview(false);
  };

  const previewAvaiable = () => {
    setLoading(false);
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const imageUrl = cropper.getCroppedCanvas().toDataURL();
      setCropData(imageUrl);
      cropper.getCroppedCanvas().toBlob((blob: Blob) => {
        onSave(blob, imageUrl);
        setPreview(true);
      });
    }
  };

  const handleEdit = () => {
    setPreview(false);
    onDelete();
  };

  const cancelEdit = () => {
    setCropData(undefined);
    setImage(undefined);
    if (imagePreviewUrl) setPreview(true);
  };

  return (
    <>
      <InputLabel text={labelText} required={required} />
      <StyledImagePicker>
        {loading && <Spinner overlay />}
        {!isPreview && !image && (
          <FileInput htmlFor="file">
            {__('application.selectFile')}
            <input type="file" id="file" onChange={onChange} />
          </FileInput>
        )}
        {!isPreview && image && (
          <>
            <StyledCropper
              zoomTo={0}
              aspectRatio={desktopRatio}
              mobileRatio={mobileRatio}
              preview=".img-preview"
              src={image}
              viewMode={2}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={0}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </>
        )}
        {isPreview && (
          <>
            {cropData && <ImagePreview src={cropData} />}
            {imagePreviewUrl && !cropData && (
              <ImagePreview
                src={`${apiUrl}${imagePreviewUrl}`}
                onError={previewUnavailable}
                onLoad={previewAvaiable}
              />
            )}
          </>
        )}
        <ButtonsContainer>
          {!isPreview && previewUrl && (
            <Button
              type="button"
              text="application.cancel"
              onClick={cancelEdit}
              marginRight={10}
              variant="danger"
            />
          )}
          {image && !isPreview && (
            <Button
              type="button"
              text="application.selectDifferentImage"
              onClick={cancelEdit}
              marginRight={10}
              variant="danger"
            />
          )}
          {isPreview && <Button type="button" text="application.edit" onClick={handleEdit} />}
          {!isPreview && image && (
            <Button type="button" text="application.save" onClick={getCropData} />
          )}
        </ButtonsContainer>
      </StyledImagePicker>
    </>
  );
};

export default ImagePicker;
