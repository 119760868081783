/* eslint-disable prefer-const */
import { CSSProperties } from 'react';
import { store } from '../config/store';
import { theme } from '../config/theme';

export const inputSelectStyles = () => {
  const borderRadius = store.getState().platformConfig?.borderRadius;
  return {
    option: (provided: CSSProperties, state: { isSelected: boolean }) => ({
      ...provided,
      minHeight: 40,
      zIndex: 999,
      borderBottom: `2px solid ${theme.colors.colorInput}`,
      color: state.isSelected ? 'white' : theme.colors.colorDark,
      backgroundColor: state.isSelected ? theme.colors.colorSecondary : 'white',
      padding: '15px',
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      '&:hover': {
        backgroundColor: theme.colors.colorInput,
      },
    }),
    control: (base: CSSProperties) => ({
      ...base,
      background: theme.colors.colorInput,
      fontFamily: theme.fontPrimary,
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      border: 'none',
      padding: '0 7px',
      minHeight: 49,
      lineHeight: 1.4,
      boxShadow: '',
      '&:hover': {
        borderColor: 'inherit',
      },
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      color: theme.colors.colorDark,
      marginTop: 0,
      zIndex: 999,
    }),
    menuList: () => ({
      color: theme.colors.colorDark,
      padding: 0,
      zIndex: 999,
      borderBottom: theme.colors.colorInput,
      maxHeight: 250,
      overflow: 'scroll',
    }),
    placeholder: () => ({
      color: theme.colors.colorDisabled,
      fontSize: 14,
      fontWeight: 300,
    }),
  };
};
