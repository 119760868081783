import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormField } from '..';
import { Column } from '../../Layout';
import { useAdditionalFields } from '../../../hooks/useAdditionalFields';

interface Props {
  form: string;
  readonly?: boolean;
  isId?: boolean;
  myProfile?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const AdditionalFields: FC<Props> = ({ form, readonly, isId, myProfile }) => {
  const { findFormByName, additionalFields } = useAdditionalFields({ myProfile });
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    findFormByName(form, isId);
  }, []);

  return (
    <>
      {additionalFields
        ? additionalFields.length > 0 &&
          additionalFields.map(
            ({
              type,
              isRequired,
              label,
              name,
              value,
              description,
              placeholder,
              conditionalOptions,
              formSelectOptions,
            }) => {
              let selectOptions;
              if (type === 'select') selectOptions = formSelectOptions;

              if (conditionalOptions?.fieldValue) {
                const { fieldName, fieldValue } = conditionalOptions;

                /**
                 * Mapping conditional fields and show when chosen 
                 * in config fieldName is equal value in field
                 * */

                if (getValues(fieldName) === fieldValue) {
                  return (
                    <Column key={name} width={type === 'checkbox' ? 1 : [1, null, null, 1 / 2]}>
                      <FormField
                        type={type}
                        labelText={label}
                        isRequired={isRequired}
                        name={value || name}
                        description={description}
                        placeholderText={placeholder}
                        options={selectOptions}
                        stringSelect
                        onChange={(e) => setValue(name, e.target.value)}
                        readonly={readonly}
                      />
                    </Column>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <Column key={name} width={type === 'checkbox' ? 1 : [1, null, null, 1 / 2]}>
                    <FormField
                      type={type}
                      labelText={label}
                      isRequired={isRequired}
                      name={value || name}
                      description={description}
                      placeholderText={placeholder}
                      options={selectOptions}
                      stringSelect
                      readonly={readonly}
                    />
                  </Column>
                );
              }
            },
          )
        : null}
    </>
  );
};
