import React, { ReactNode } from 'react';
import { TableFieldSearchableValues, TableSchemaField, FieldType } from 'blocal-tables';
import { __ } from '../../../helpers/i18n';
import './SearchBar.scss';

interface Props {
  padded?: boolean;
}

export default function searchComponent(
  allFields: TableSchemaField[],
  search: { searchBy: string; searchQuery: string },
  sort: { sortBy: string; sortDirBack: boolean } | null,
  onSearchChange: (searchBy: string, searchQuery: string) => void,
  onSortChange?: (sortBy: string, sortDirection: boolean) => void,
  padded?: boolean,
  inModal?: boolean,
  borderRadius?: number,
  additionalComponent?: ReactNode,
) {
  const handleSearchQueryChange = (searchQuery: string) => {
    onSearchChange(search.searchBy, searchQuery);
  };

  const handleSearchByChange = (searchBy: string) => {
    onSearchChange(searchBy, search.searchQuery);
  };

  const handleSortByChange = (sortBy: string) => {
    if (typeof onSortChange !== 'undefined') {
      const sortParsed = JSON.parse(sortBy);
      onSortChange(sortParsed.value, sortParsed.dir);
    }
  };

  const getSearchElement = () => {
    const field = allFields.find((f) => f.field === search.searchBy);

    if (!field) {
      return null;
    } else if (field.type === FieldType.Boolean) {
      return booleanInput();
    } else if (field.searchableValues && typeof field.searchableValues === 'object') {
      return objectInput(field);
    } else {
      return textInput();
    }
  };

  const booleanInput = () => {
    return (
      <select
        className="input"
        placeholder={__('appliaction.selecctValue')}
        value={search.searchQuery}
        onChange={(e: any) => {
          handleSearchQueryChange(e.currentTarget.value);
        }}
      >
        <option value={''} key={-1}>
          {''}
        </option>
        <option value={1} key={1}>
          {__('application.yes')}
        </option>
        <option value={0} key={0}>
          {__('application.no')}
        </option>
      </select>
    );
  };

  const objectInput = (field: TableSchemaField) => {
    if (!field || !field.searchableValues) return null;
    return (
      <select
        className="input"
        placeholder={__('application.selectValue')}
        value={search.searchQuery}
        onChange={(e) => handleSearchQueryChange(e.target.value)}
      >
        <option value={''} key={-1}>
          {__('application.selectValue')}
        </option>
        {Object.keys(field.searchableValues as TableFieldSearchableValues).map((key) => (
          <option value={key} key={key}>
            {field!.searchableValues![key]}
          </option>
        ))}
      </select>
    );
  };

  const textInput = () => {
    return (
      <>
      <input
        className="search-input"
        type="text"
        placeholder={__('application.search')}
        value={search.searchQuery}
        onChange={(e) => handleSearchQueryChange(e.target.value)}
      />
      {additionalComponent}
      </>
    );
  };

  return (
    <div className={`search-bar ${inModal ? 'in-modal' : ''}`}>
      <div className="search-header-cont">
        {/* <FontAwesomeIcon icon={faSearch} className="search-icon" /> */}
        <h4 className="search-header">{__('application.searchBy')}</h4>
      </div>
      <div className={`search-fields ${inModal ? 'in-modal' : ''}`}>
        <div className="search-field">
          <select value={search.searchBy} onChange={(e) => handleSearchByChange(e.target.value)}>
            {search.searchBy === '' ? (
              <option value="">{__('application.select_the_field_you_want_to_search')}</option>
            ) : null}
            {allFields
              .filter((field) => field.searchable === true)
              .map((field) => (
                <option value={field.field} key={field.field}>
                  {__(field.name)}
                </option>
              ))}
          </select>
        </div>
        <div className="search-query">{getSearchElement()}</div>
      </div>

      {sort && (
        <>
          <div className="search-header-cont">
            <h4 className="search-header">{__('sort.header')}</h4>
          </div>
          <div className={`search-fields ${inModal ? 'in-modal' : ''}`}>
            <div className="search-field">
              <select
                value={JSON.stringify({ value: sort.sortBy, dir: sort.sortDirBack })}
                onChange={(e) => handleSortByChange(e.target.value)}
              >
                {sort.sortBy === '' ? <option value="">{__('application.chooseSearchMethod')}</option> : null}
                {allFields
                  .filter((field) => field.sortable === true)
                  .map((field) => (
                    <>
                      <option value={JSON.stringify({ value: field.field, dir: true })} key={field.field}>
                        {__(field.name)} {__('sort.descending')}
                      </option>
                      <option value={JSON.stringify({ value: field.field, dir: false })} key={field.field}>
                        {__(field.name)} {__('sort.ascending')}
                      </option>
                    </>
                  ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
