import { useState } from 'react';
import { StatsRes } from 'blocal-types';
import { useApi } from '../services/useApi';

interface UseBasicStatistics {
  getStats: (tenantId: string) => void;
  stats: StatsRes | undefined;
}
export interface StaticticsProps {
  _id: number;
  number?: number;
  icon: string;
  description: string;
  backgroundColor: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useBasicStatistics = (): UseBasicStatistics => {
  const { api } = useApi();
  const [stats, setStats] = useState<StatsRes>();

  const getStats = (tenantId: string) => {
    api<StatsRes>({
      method: 'GET',
      path: `report/stats/${tenantId}`,
      onSuccess: (data) => {
        setStats(data);
      },
    });
  };

  return { getStats, stats };
};
