import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import { Column } from '../../Layout';
import { StyledWrapper } from "../../../views/Dashboard/Articles/View";
import { useTheme } from 'styled-components';

interface VideoPlayerProps {
  videoSrc: string;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoSrc }) => {
  const { colors } = useTheme();
  return (
    <Column width={1} alignItems="center" justifyContent="center">
      <StyledWrapper>
        <ReactPlayer
          className="react-player"
          controls
          playsInline
          height="100%"
          width="100%"
          config={{
            vimeo: { playerOptions: { color: colors.colorPrimary.slice(1) } },
          }}
          url={videoSrc}
        />
      </StyledWrapper>
    </Column>
  );
};
