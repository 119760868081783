/* eslint-disable prefer-const */
import React, { FC, useState, useEffect } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformConfig, getFullPlatformConfig } from '../../../slices/platformConfigSlice';
import { theme } from '../../../config/theme';
import { RootState } from '../../../reducers';
import { Spinner } from '..';

// eslint-disable-next-line import/prefer-default-export
export const AppWrapper: FC = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const [customTheme, setCustomTheme] = useState<DefaultTheme>(theme);
  const configColors = useSelector((state: RootState) => state?.platformConfig?.colors);
  const borderRadius = useSelector((state: RootState) => state?.platformConfig?.borderRadius);

  useEffect(() => {
    dispatch(getPlatformConfig());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getFullPlatformConfig());
    }
  }, [user]);

  useEffect(() => {
    if (configColors) {
      setCustomTheme({
        ...theme,
        borderRadius: typeof borderRadius === 'undefined' ? theme.borderRadius : borderRadius,
        colors: { ...theme.colors, ...configColors },
      });
    }
  }, [configColors]);

  if (!configColors) return <Spinner />;

  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};
