import axios, { Method } from 'axios';
import { axiosConfig } from '../config/axios';
import { store } from '../config/store';
import { errorNotification } from '../actions/notifications';

// eslint-disable-next-line import/prefer-default-export
export const apiService = async <T = void>(
  path: string,
  method: Method,
  onSuccess?: () => void,
  onError?: (error: any) => void,
  ignore?: boolean,
  data?: T
) => {
  try {
    const res = await axios(axiosConfig(path, method, data));
    if (onSuccess) onSuccess();
    const response = res?.data?.payload ?? res.data;
    return response;
  } catch (error) {
    const { response } = error;
    if (!ignore) {
      store.dispatch(errorNotification(response.data.error_code));
    }
    if (onError) onError(error);
    throw error;
  }
};
