import { combineReducers } from '@reduxjs/toolkit';
import appStatusReducer from './app-status-reducer';
import modalReducer from './modal-reducer';
import notificationsReducer from './notifications-reducer';
import langReducer from './lang-reducer';
import tableReducer from './table-reducer';
import userReducer from '../slices/userSlice';
import platformConfigReducer from '../slices/platformConfigSlice';
import customFormSchemaReducer from '../slices/customFormSchema';
import selectedTenantAndModulesReducer from '../slices/selectedTenantAndModules';
import apiListReducer from './api-list-reducer';
import groupsReducer from '../slices/groupsSlice';
import communicationReducer from './communication-reducer';
import cartReducer from '../slices/cartSlice';
import headerReducer from '../slices/headerSlice';
import reportReducer from '../slices/reportSlice';
import quizReducer from '../slices/quizSlice';


const rootReducer = combineReducers({
  appStatus: appStatusReducer,
  lang: langReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  header: headerReducer,
  user: userReducer,
  platformConfig: platformConfigReducer,
  apiTable: tableReducer,
  apiList: apiListReducer,
  customFormSchema: customFormSchemaReducer,
  selectedTenantAndModules: selectedTenantAndModulesReducer,
  groups: groupsReducer,
  communication: communicationReducer,
  cart: cartReducer,
  report: reportReducer,
  quiz: quizReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
