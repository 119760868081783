import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  color,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { __ } from '../../../helpers/i18n';

interface TextProps extends LayoutProps, SpaceProps, TypographyProps {
  as?: 'span' | 'h1' | 'h2' | 'h3' | 'h4';
  fontWeight?: 'light' | 'number' | 'bold' | number;
  textAlign?: 'center' | 'right' | 'left';
  zIndex?: number;
  textTransform?: 'uppercase';
  color?: string;
  content?: string;
  whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'inherit';
}

export const StyledText = styled.p<TextProps>`
  font-weight: ${({ fontWeight }) => fontWeight ?? '300'};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  text-transform: ${({ textTransform }) => textTransform ?? 'none'};
  padding: 0;
  white-space: ${({ whiteSpace }) => whiteSpace ?? 'normal'};

  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
  display: ${({ display }) => display ?? 'block'};
  ${layout}
  ${space}
  ${typography}
  ${color}
`;

// eslint-disable-next-line import/prefer-default-export
export const Text: FC<TextProps> = ({
  children,
  fontWeight,
  display,
  zIndex,
  textTransform,
  width,
  as,
  color,
  whiteSpace,
  content,
  ...props
}) => (
  <StyledText
    color={color}
    fontWeight={fontWeight}
    display={display}
    zIndex={zIndex}
    textTransform={textTransform}
    width={width}
    whiteSpace={whiteSpace}
    as={as}
    {...props}
  >
    {content ? __(content) : children}
  </StyledText>
);
