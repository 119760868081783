import ApiService from '../services/api-service';

export const fetchApiListData = (url: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'FETCH_API_LIST_DATA_SUCCESS',
    'FETCH_API_LIST_DATA_FAILED',
    null,
    null,
    url,
    'LOADING_API_LIST',
  );
};

export const refreshApiList = () => ({ type: 'REFRESH_API_LIST' });
