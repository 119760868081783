import axios, { AxiosRequestConfig, Method } from 'axios';
import { UserRole } from 'blocal-types';
import { apiUrl } from './apiUrl';
import { store } from './store';
import { history } from '../App';
import { logout } from '../slices/userSlice';

export const getCorrectTenantId = (): string => {
  const userRole = store.getState().user.details?.role;

  const isSuperAdmin = userRole === UserRole.SuperAdmin;
  let tenantId: string;

  if (isSuperAdmin) {
    tenantId = store.getState().selectedTenantAndModules?.tenant?.value;
    return tenantId || store.getState()?.platformConfig?.config?._id;
  } else {
    tenantId = store.getState()?.platformConfig?.config?._id;
  }

  if (tenantId) return tenantId;
  else return '';
};

export const axiosConfig = (path: string, method: Method, data?: any, params?: string, timeout?: number): AxiosRequestConfig => ({
  withCredentials: true,
  timeout: timeout ?? 15000,
  url: apiUrl + path,
  method,
  data,
  params: { tenantId: getCorrectTenantId(), params },
});

export const axios401Interceptor = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(logout(null))
        history.push('/');
      }
      return Promise.reject(error);
    },
  );
};
