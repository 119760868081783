import { rgba } from 'polished';
import React from 'react';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CarouselButton } from '..';
import { RootState } from '../../../reducers';

const StyledSwiperNavigation = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 999;

  .swiper-button-disabled {
    opacity: 0;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const SwiperNavigation = () => {
  const configColors = useSelector((state: RootState) => state?.platformConfig?.colors);

  return (
    <StyledSwiperNavigation>
      <div className="swiper-button-prev-unique">
        <CarouselButton>
          <FaChevronCircleLeft size={30} color={rgba(configColors.colorPrimary, 0.75)} />
        </CarouselButton>
      </div>
      <div className="swiper-button-next-unique">
        <CarouselButton>
          <FaChevronCircleRight size={30} color={rgba(configColors.colorPrimary, 0.75)} />
        </CarouselButton>
      </div>
    </StyledSwiperNavigation>
  );
};
