import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomSelectOption, ReportActions, ReportRes } from 'blocal-types';
import { apiService } from '../services/apiService';

export interface ReportState {
  selectedReport: ReportRes | null;
  availableReports: ReportRes[] | null;
  reportBar: {
    reportType: CustomSelectOption<ReportActions> | null,
    startDt: Date | null;
    endDt: Date | null;
  };
}

const initialState: ReportState = {
  selectedReport: null,
  availableReports: null,
  reportBar: {
    reportType: null,
    startDt: null,
    endDt: null,
  },
};

export const getAvailablesReports = createAsyncThunk(
  'reports/getAvailableReports',
  async () => await apiService('report/get-available-reports', 'GET'),
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReportType: (state, action: PayloadAction<CustomSelectOption<ReportActions>>) => {
      state.reportBar.reportType = action.payload
    },
    setSelectedReport: (state, action: PayloadAction<ReportRes>) => {
      state.selectedReport = action.payload
    },
    setStartDt: (state, action: PayloadAction<Date>) => {
      state.reportBar.startDt = action.payload;
    },
    setEndDt: (state, action: PayloadAction<Date>) => {
      state.reportBar.endDt = action.payload;
    },
    clearReportBar: (state) => {
      state.reportBar = {
        reportType: null,
        startDt: null,
        endDt: null,
      };
    }
  }, 
  extraReducers: {
    [getAvailablesReports.fulfilled as any]: (state, action: PayloadAction<ReportRes[]>) => {
      state.availableReports = action.payload;
    },
  },
});

export const { setSelectedReport, setEndDt, setStartDt, setReportType, clearReportBar } = reportSlice.actions;

export default reportSlice.reducer;
