import { Permission, UserRole } from 'blocal-types';
import React, { FC } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Button } from '..';
import { gtMedia, ltMedia } from '../../../config/config';
import { inputSelectStyles } from '../../../helpers/additionalStyles';
import { __ } from '../../../helpers/i18n';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { SelectFilterTable } from '../../../views/Dashboard/Users/Manage';
import { Column, ButtonsContainer } from '../../Layout';
import { StyledColumn } from '../../Layout/Column';
import { StyledRow } from '../../Layout/Row';

const StyledFilterAndExport = styled(StyledRow)`
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 2.5)`};
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;

  .button {
    margin-bottom: 0;
  }
`;

const FilterColumn = styled(StyledColumn)`
  margin-bottom: 0;
  ${ltMedia('md')} {
    margin-bottom: ${({ theme }) => theme.mainSpacing};
  }
  ${gtMedia('md')} {
    max-width: 320px;
  }
`;
interface Props {
  selectOptions: () => ({ label: string; value: string } | { label: string; value: UserRole })[];
  handleOnChange: (e: any) => void;
  filterBy: SelectFilterTable;
  handleOpen?: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const FilterAndExport: FC<Props> = ({
  selectOptions,
  handleOnChange,
  filterBy,
  handleOpen,
}) => {
  const { hasAccess } = useUserAccess();
  return (
    <StyledFilterAndExport width={1} m={0} flexWrap="wrap">
      <FilterColumn width={[1, null, 1 / 3]}>
        <Select
          options={selectOptions()}
          styles={inputSelectStyles()}
          onChange={(e) => handleOnChange(e)}
          name="filter"
          placeholder={__('application.choose')}
          value={filterBy}
        />
      </FilterColumn>
      {hasAccess(Permission.ExportUsers) && (
        <Column padding={0} width={[1, null, 1 / 3]}>
          <ButtonsContainer>
            <Button small onClick={handleOpen} text="application.exportToCsv" />
          </ButtonsContainer>
        </Column>
      )}
    </StyledFilterAndExport>
  );
};
