import React, { FC, useState, CSSProperties } from 'react';
import Select, { OptionProps } from 'react-select';
import { lighten } from 'polished';

import { StyledViewsTabs, StyledTab } from '../../Styled/StyledTabs';
import { useMobile } from '../../../hooks/useMobile';
import { __ } from '../../../helpers/i18n';
import { View } from '../../../types/view';

/* eslint-disable prefer-const */
import { store } from '../../../config/store';
import { theme } from '../../../config/theme';

export const viewsSelectStyles = () => {
  const borderRadius = store.getState().platformConfig?.borderRadius;
  const colors = store.getState().platformConfig?.colors;
  return {
    option: (provided: CSSProperties, state: { isSelected: boolean }) => ({
      ...provided,
      height: 40,
      zIndex: 999,
      borderBottom: `1px solid ${colors.colorInput}`,
      color: state.isSelected ? 'white' : colors.colorDark,
      backgroundColor: state.isSelected ? lighten(0.2, `${colors.colorSecondary}`) : 'white',
      padding: '15px',
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      '&:hover': {
        backgroundColor: colors.colorInput,
      },
      ':active': {
        backgroundColor: colors.colorSecondary,
      },
    }),
    control: (base: CSSProperties) => ({
      ...base,
      border: `1px solid ${colors.colorSecondary}`,
      fontFamily: theme.fontPrimary,
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      padding: '0 7px',
      height: 49,
      minHeight: 49,
      lineHeight: 1.4,
      ':hover': {
        border: `1px solid ${colors.colorSecondary}`,
      },
      
      marginBottom: 20,
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      color: colors.colorTextSecondary,
      marginTop: 0,
      zIndex: 999,
    }),
    menuList: (base: CSSProperties) => ({
      ...base,
      color: colors.colorDark,
      padding: 0,
      zIndex: 999,
      borderRadius: `${borderRadius || theme.borderRadius}px`,
      border: `1px solid ${colors.colorSecondary}`,
    }),
  };
};

interface ViewsTabsProps {
  views: View[];
  defaultActive?: null | number;
}

// eslint-disable-next-line import/prefer-default-export
export const ViewsTabs: FC<ViewsTabsProps> = ({ views, defaultActive }) => {
  const [activeTab, setActiveTab] = useState<number | null>(
    typeof defaultActive !== 'undefined' ? defaultActive : 0,
  );
  const { isMobile } = useMobile();
  const viewsWithAccess = views.filter((view) => view.isPermitted);

  if (isMobile) {
    const selectedView = viewsWithAccess.find((view, index) => index === activeTab);
    return (
      <Select
        name="view"
        options={viewsWithAccess.map((view, index) => ({ value: index, label: __(view.name) }))}
        styles={viewsSelectStyles()}
        onChange={(e: any) => {
          setActiveTab(e.value);
          viewsWithAccess[e.value].onChange();
        }}
        value={
          activeTab !== null
            ? { value: activeTab, label: `${__(selectedView?.name)}` }
            : {
                value: -1,
                label: __('application.choose'),
              }
        }
      />
    );
  }

  return (
    <StyledViewsTabs>
      {viewsWithAccess.map((view, index) => {
        const { name, onChange } = view;
        return (
          <StyledTab
            key={view.name}
            isFirst={index === 0}
            width={100 / viewsWithAccess.length}
            isActive={index === activeTab}
            onClick={() => {
              setActiveTab(index);
              onChange();
            }}
          >
            {__(name)}
          </StyledTab>
        );
      })}
    </StyledViewsTabs>
  );
};
