import { useState } from 'react';
import { ArticlePlace, ArticleRes } from 'blocal-types';
import { useApi } from '../../services/useApi';

interface UseListArticle {
  articles: ArticleRes[];
  promoted: ArticleRes[];
  loading: boolean;
  isPromoted: boolean;
  getArticles: (type: ArticlePlace, number: number) => void;
  isNewsSection: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useListArticle = (): UseListArticle => {
  const { api, loading } = useApi();
  const [articles, setArticles] = useState<ArticleRes[]>([]);
  const [promoted, setPromoted] = useState<ArticleRes[]>([]);
  const [isPromoted, setIsPromoted] = useState(false);

  const getArticles = (type: ArticlePlace, number: number) => {
    api({
      method: 'GET',
      path: `article/list/all/${type}/${number}`,
      onSuccess: (data: ArticleRes[]) => {
        setArticles([...promoted, ...data]);
      },
    });
  };

  const isNewsSection = () => {
    api({
      method: 'GET',
      path: `article/list/all/${ArticlePlace.Promoted}/1`,
      onSuccess: (data: ArticleRes[]) => {
        setPromoted(data);
        if (data.length > 0) {
          setIsPromoted(true);
        }
      },
    });
    getArticles(ArticlePlace.News, 2);
  };

  return { articles, promoted, loading, getArticles, isNewsSection, isPromoted };
};
