/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectOption } from 'blocal-types';
import { useApi } from '../services/useApi';
import { SelectOptions } from '../types/formFieldTypes';
import { getTenantActiveModules, setSelectedTenant } from '../slices/selectedTenantAndModules';

interface UseTenant {
  modules: any[];
  forms: SelectOptions[];
  tenants: SelectOptions[];
  handleLoadActiveModuleAndSelectTenand: (tenant: SelectOption) => void;
}

/**
 *  @About Logic for tenants related actions
 */

export const useTenant = (): UseTenant => {
  const dispatch = useDispatch();
  const { api } = useApi();
  const [modules, setModules] = useState<SelectOption[]>([]);
  const [forms, setForms] = useState<SelectOptions[]>([]);
  const [tenants, setTenants] = useState<SelectOptions[]>([]);

  const getModules = () => {
    api<SelectOption[]>({
      method: 'GET',
      path: 'assets/all/not-default-segments',
      onSuccess: (res) => setModules(res),
    });
  };

  const getFormList = async () => {
    api<SelectOptions[]>({
      method: 'GET',
      path: 'form/get-ids',
      onSuccess: (res) => setForms(res),
    });
  };

  const getTenantList = async () => {
    api<SelectOptions[]>({
      method: 'GET',
      path: 'tenant/get-ids',
      onSuccess: (res) => setTenants(res),
    });
  };

  const handleLoadActiveModuleAndSelectTenand = (tenant: SelectOption) => {
    dispatch(setSelectedTenant(tenant));
    dispatch(getTenantActiveModules(tenant.value));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getModules();
      await getFormList();
      await getTenantList();
    };
    fetchData();
  }, []);

  return { modules, forms, tenants, handleLoadActiveModuleAndSelectTenand };
};
