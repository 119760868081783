import { SuperUserRes } from 'blocal-types';
import React, { FC } from 'react';
import { SectionTitle } from '..';
import { __ } from '../../../helpers/i18n';
import ApiService from '../../../services/api-service';
import { Column } from '../../Layout';
import { usePlatform } from '../../../hooks/usePlatform';

import './MySuperuser.scss';

interface Props {
  superUser: SuperUserRes;
}

// eslint-disable-next-line import/prefer-default-export
export const MySuperuser: FC<Props> = ({ superUser }) => {
  const { tenantQuery } = usePlatform();
  return (
    <Column width={1}>
      <SectionTitle>Twój opiekun</SectionTitle>
      <div className="my-superuser">
        <img className="avatar" src={`${ApiService.url}user/superuser-avatar/${tenantQuery}`} alt="Awatar opiekuna" />
        <div className="content">
          <SectionTitle>
            {superUser?.firstName} {superUser?.lastName}
          </SectionTitle>
          <a className="contact-data" href={`mailto:${superUser?.email}`}>
            {superUser?.email}
          </a>
          <a className="contact-data" href={`tel:${superUser?.tel}`}>
            {superUser?.tel}
          </a>
        </div>
      </div>
    </Column>
  );
};
