import { ApiErrorCode } from 'blocal-types';

interface Notification<T> {
  type: string;
  payload: T;
}

export const successNotification = (payload: string): Notification<string> => ({
  type: 'SUCCESS_NOTIFICATION',
  payload,
});

export const errorNotification = (error: ApiErrorCode): Notification<ApiErrorCode> => ({
  type: 'ERROR_NOTIFICATION',
  payload: error,
});

export const hideNotification = (id: string): Notification<string> => ({
  type: 'HIDE_NOTIFICATION',
  payload: id,
});
