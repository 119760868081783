import React, { FC } from 'react';
import { ContentRemover } from '..';
import { StyledFileWrapper } from '../../Styled';

const PhotoWrapper: FC<{ deletePhotos?: () => void }> = ({ children, deletePhotos }) => (
  <StyledFileWrapper>
    {children}
    {deletePhotos && <ContentRemover handleRemove={deletePhotos} bgColor="#ffffff" />}
  </StyledFileWrapper>
);

export default PhotoWrapper;
