import React, { FC } from 'react';
import { Button } from '..';
import { Text } from '../..';

interface PaginationButtonProps {
  number?: string | number;
  active?: boolean;
  onClick: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const PaginationButton: FC<PaginationButtonProps> = ({ number, active, onClick }) => (
  <Button
    width="30px"
    height="30px"
    padding="0"
    mr="10px"
    onClick={onClick}
    fontWeight={600}
    color="colorPrimary"
    backgroundColor="colorLight"
    borderColor={active ? 'colorPrimary' : undefined}
  >
    <Text mb="0" fontWeight={600}>{`${number}`}</Text>
  </Button>
);
