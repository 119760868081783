import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { space, flexbox, layout, FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { gtMedia } from '../../../config/config';
import { StyledRow } from '../Row';

interface StyledProps extends LayoutProps, SpaceProps, FlexboxProps {
  $max?: boolean;
  sideR?: boolean;
  wrap?: boolean;
}

export const StyledButtonsContainer = styled(StyledRow)<StyledProps>`
  width: 100%;
  display: flex;
  button {
    margin-bottom: ${({ theme }) => theme.mainSpacing};
    &:last-of-type {
      margin-bottom: 0;
    }

    ${gtMedia('sm')} {
      &:last-of-type {
        margin-left: ${({ theme }) => theme.mainSpacing};
        margin-bottom: ${({ theme }) => theme.mainSpacing};
      }
      &:first-of-type {
        margin-left: 0;
      }
    }

    ${gtMedia('sm')} {
      flex-direction: row;
      button,
      a {
        &:first-of-type {
          margin-bottom: 0;
        }
        margin-right: ${({ theme }) => theme.mainSpacing};
        &:last-child {
          ${css`
            margin-right: 0;
          `}
        }
      }
    }

    ${({ $max }) =>
      $max &&
      css`
        padding: 0;
        button,
        a {
          width: 100%;
          &:first-of-type {
            ${css`
              margin: ${({ theme }) => theme.mainSpacing} 0;
            `}
          }
        }
      `};

    ${({ sideR }) =>
      sideR &&
      css`
        padding-right: 0;
      `};
  }
  ${space}
  ${flexbox}
  ${layout}
`;
interface Props extends StyledProps {
  max?: boolean;
}

const ButtonsContainer: FC<Props> = ({ children, max, sideR, wrap, ...props }) => (
  <StyledButtonsContainer
    justifyContent={['column', null, 'row']}
    $max={max}
    sideR={sideR}
    wrap={wrap}
    {...props}
  >
    {children}
  </StyledButtonsContainer>
);

export default ButtonsContainer;

ButtonsContainer.defaultProps = {
  justifyContent: 'flex-end',
  display: 'flex',
  mt: 0,
  mb: 0,
  mr: 0,
  ml: 0,
};
