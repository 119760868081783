/* eslint-disable no-template-curly-in-string */
import { css, DefaultTheme } from 'styled-components';

interface FormInput {
  colorDark: string;
  colorInput: string;
  colorGray: string;
  colorPrimary: string;
  colorDanger: string;
  mainSpacing: string;
  fontPrimary: string;
  borderRadius: number;
  transitionTime?: string;
  isValid?: boolean;
  inputBig?: boolean;
  checkbox?: boolean | undefined;
  textarea?: boolean | undefined;
  customColor?: boolean;
  icon?: boolean;
}

export const formInput = (props: FormInput): any => {
  const {
    colorDark,
    mainSpacing,
    fontPrimary,
    borderRadius,
    transitionTime,
    colorDanger,
    colorPrimary,
    colorInput,
    colorGray,
    checkbox,
    textarea,
    customColor,
    icon,
  } = props;
  return css`
    input,
    textarea {
      color: ${colorDark};
      border: none;
      background-color: ${colorInput};
      padding: ${customColor ? `calc(${mainSpacing} * .5)` : `calc(${mainSpacing} * 1.5)`};
      font-size: 1.4rem;
      font-weight: 400;
      font-family: ${fontPrimary};
      border-radius: ${borderRadius}px;
      resize: vertical;
      ${icon &&
      css`
        padding-right: 4rem;
      `};
      ${!checkbox &&
      css`
        width: 100%;
      `};
      ${checkbox &&
      css`
        display: flex;
        background: red;
      `}
      ${textarea &&
      css`
        min-height: 80px;
      `};
      ${customColor &&
      css`
        height: 40px;
      `};
      outline: none;
      transition: ${transitionTime};
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: ${colorDark} !important;
        box-shadow: 0 0 0px 1000px ${colorGray} inset !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      &::placeholder {
        color: ${colorGray};
        font-weight: 400;
      }

      &:focus {
        box-shadow: 0 calc(${mainSpacing} / 2) calc(${mainSpacing} * 2) calc(${mainSpacing} * (-1))
          rgba(${colorPrimary}, 0.5);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      ${props?.isValid &&
      css`
      border: 1px solid ${colorDanger};
      box-shadow: 0 calc(${mainSpacing}/2) calc(${mainSpacing} * 2);
      calc(${mainSpacing} * (-1)) rgba(${colorDanger}, 0.5);
      background-color: lighten(${colorDanger}, 50%);
     `}

      ${props?.inputBig &&
      css`
        text-align: center;
        font-size: 1.5rem;
        padding: ${mainSpacing};
      `}
    }
  `;
};

export const theme: any = {
  space: [0, 10, 20, 30, 40],
  colors: {
    colorPrimary: '#c1c7d0',
    colorSecondary: '#c1c7d0',
    colorBackground: '#f2f4f7',
    colorInput: '#f2f4f7',
    colorLight: '#fff',
    colorGray: '#c1c7d0',
    colorDark: '#000',
    colorTextSecondary: '#ed1c26',
    colorDanger: '#a51727',
    colorNotice: '#bb9c71',
    colorSuccess: '#00a45a',
    colorWarning: '#ec8628',
    colorDisabled: '#aaa',
  },
  fontPrimary: 'blocal, "Open Sans", sans-serif',
  _fontSizes: [
    {
      name: 'extraSmall',
      size: '1rem',
    },
    {
      name: 'small',
      size: '1.2rem',
    },
    {
      name: 'normal',
      size: '1.4rem',
    },
    {
      name: 'medium',
      size: '1.6rem',
    },
    {
      name: 'big',
      size: '1.8rem',
    },
    {
      name: 'extraBig',
      size: '2.2rem',
    },
  ],
  _breakpoints: [
    {
      name: 'xs',
      size: '368px',
    },
    {
      name: 'sm',
      size: '768px',
    },
    {
      name: 'md',
      size: '920px',
    },
    {
      name: 'lg',
      size: '1200px',
    },
    {
      name: 'xl',
      size: '1368px',
    },
  ],
  breakpoints: ['368px', '768px', '920px', '1200px', '1368px'],
  fontSizes: ['1rem', '1.2rem', '1.6rem', '1.8rem', '2.2rem'],
  mainSpacing: '10px',
  transitionTime: '0.3s',
  borderRadius: 0,
  borderRound: '50%',
  checkboxSize: '15px',
  maxPageWidth: '1300px',
  desktopNavWidth: '100px',
  mobileNavHeight: '55px',
  headerHeight: '70px',
  notificationsContainerWidth: '300px',
  notificationsContainerHeight: '300px',
  notificationIconSize: '40px',
  headerHeightDesktop: '130px',
  userImageSizeMobile: '100px',
  userImageSizeDesktop: '185px',
  userImageOffsetMobile: '80px',
  userImageOffsetDesktop: '100px',
  mixins: {
    formInput: (props: FormInput): any => formInput(props),
  },
  softShadow:
    '0 0 calc((${theme.mainSpacing}) * 2.5) calc((${theme.mainSpacing})/2) rgba(0, 0, 0, 0.15)',
  softShadowBottom:
    '0 ${theme.mainSpacing} ${theme.mainSpacing} calc(-var(theme.mainSpacing)/2) rgba(0, 0, 0, 0.15)',
};
