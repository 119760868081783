import { DefaultUserFields, ModPerm, UserRole, ActiveModulesRes } from 'blocal-types';
import React, { Dispatch, FC, useReducer, useCallback, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { successNotification } from '../../actions/notifications';
import { handlingError } from '../../helpers/handlingErrors';
import { __ } from '../../helpers/i18n';
import { useApi } from '../../services/useApi';
import { MultimediaFile } from '../../types/photo';
import { history } from '../../App';
import {
  ModeratorAction,
  ModeratorManageReducer,
  moderatorManageReducer,
  initState,
  SegmentSelectOption,
} from './useModeratorManageReducer';

export const ModeratorManageDispatch = React.createContext<Dispatch<ModeratorAction> | null>(null);
export const ModeratorManage = React.createContext<ModeratorManageReducer | null>(null);

export const ModeratorManageProvider: FC = ({ children }) => {
  const [moderator, dispatch] = useReducer(moderatorManageReducer, initState);
  const rDispatch = useDispatch();
  const { api } = useApi();
  const { userId } = useParams<{ userId: string }>();

  const getModules = useCallback(() => {
    api<ActiveModulesRes>({
      method: 'GET',
      path: 'tenant/active-modules',
      onSuccess: (res) => {
        dispatch({
          type: 'setAllModules',
          payload: res.segments.map(({ _id, segment, segmentNameForTenant  }) => ({
            value: _id,
            modulesAction: segment?.moduleActions,
            label: segmentNameForTenant,
          })),
        })
      },
    });
  }, []);

  const handleModuleChange = useCallback((modules: SegmentSelectOption[]) => {
    dispatch({ type: 'setModule', payload: modules });
    dispatch({ type: 'setActionsForModule' });
  }, [moderator.chosenModules, moderator.chosenActions]);

  const handleActionChange = useCallback((actions: ModPerm[]) => {
    dispatch({ type: 'setAction', payload: actions })
    dispatch({ type: 'setActionsForModule' });
  }, [moderator.chosenActions, moderator.chosenModules, moderator.allActions]);

  //TODO BAD SOLUTION PHOTOS SHOULD BE OUTSIDE CONTEXT, BUT NO TIME FOR FIX :(. DO IT LATER
  const submit = useCallback((payload: DefaultUserFields, e: MouseEvent, methods: UseFormMethods<Record<string, any>>, userImage?: MultimediaFile[], newUserId?: string) => {
    e.preventDefault();
    const path = newUserId ? `user/change-role/${userId}` : userId ? `user/edit-moderator/${userId}` : 'user/add-moderator';
    const method = userId || newUserId ? 'PATCH' : 'POST';
    let data;
    if (newUserId) {
      data = {
        role: UserRole.Moderator,
        moderatorModules: moderator.chosenModules,
        moderatorPermission: moderator.chosenActions,
      };
    } else if (userId) {

      const formData = new FormData();
      formData.append('body', JSON.stringify({
        ...payload,
        moderatorModules: moderator.chosenModules,
        moderatorPermission: moderator.chosenActions,
      }));

      if (userImage) {
        userImage.forEach((photo) => photo.file.forEach((singleFile: any) => formData.append(photo.fieldName, singleFile)));
      }

      data = formData;
 
    } else {
      data = {
        ...payload,
        moderatorModules: moderator.chosenModules,
        moderatorPermission: moderator.chosenActions,
      };

    }

    api<any, any>({
      path,
      method,
      data,
      onSuccess: () => {
        if (newUserId){
          rDispatch(successNotification(__('application.roleChangedProperly')));
          history.push('/dashboard/users');
        } else if (!userId) {
          rDispatch(successNotification(__('application.modAdded')));
          dispatch({ type: 'clearModules' });
          dispatch({ type: 'clearActions' });
          methods.reset();
        } else {
          rDispatch(successNotification(__('application.modEdited')));
        }
      },
      onError: (err) => {
        handlingError(err?.response, methods.setError);
      }
    })
  }, [moderator.chosenActions, moderator.chosenModules]);

  useEffect(() => {
    getModules();
  }, []);

  const value: any = {
    state: moderator,
    handleModuleChange: handleModuleChange,
    handleActionChange: handleActionChange,
    submit: submit,
  };

  return (
    <ModeratorManageDispatch.Provider value={dispatch}>
      <ModeratorManage.Provider value={value}>{children}</ModeratorManage.Provider>
    </ModeratorManageDispatch.Provider>
  );
};
