
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';



export const HelmetConfig = () => {
  const platformConfig = useSelector((state: RootState) => state.platformConfig?.config);

  return (
    <Helmet>

      <meta
        name="description"
        content={platformConfig?.description}
      />

      <meta property="og:url" content="" />
      <meta
        property="og:description"
        content={platformConfig?.description}
      />
      <meta property="og:title" content={platformConfig?.title ?? ''} />
      <title>{platformConfig?.title ?? ''}</title>
    </Helmet>
  )
}