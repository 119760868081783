import React, { FC } from 'react';
import { TenantSegment } from 'blocal-types';
import { useSelector } from 'react-redux';
import { Alert, ActionsBoxesContainer } from '..';
import { RootState } from '../../../reducers';
import UserActionBox from '../UserActionBox';
import { apiUrl } from '../../../config/apiUrl';
import { __ } from '../../../helpers/i18n';

// eslint-disable-next-line import/prefer-default-export
export const SuperAdminBoxes: FC = () => {
  const modulesForTenant = useSelector(
    (state: RootState) => state?.selectedTenantAndModules?.activeModules,
  );
  const selectedPlatormId = useSelector(
    (state: RootState) => state.selectedTenantAndModules?.tenant?.value,
  );

  if (!selectedPlatormId || !modulesForTenant) {
    return <Alert simple type="error" text="application.noModules" />;
  }

  return (
    <ActionsBoxesContainer>
      <UserActionBox
        backgroundColor="#642934"
        path="/dashboard/tenants/registration-config"
        text={__('application.config&rulesRegistration')}
      />
      <UserActionBox backgroundColor="#642934" path="/dashboard/tenants/custom-form" text="Formularze" />
      {modulesForTenant
        .filter((box) => box.segment)
        .map((box: TenantSegment) => {
          const { segment, segmentBg, segmentNameForTenant, _id, fileFn } = box;
          return (
            <UserActionBox
              key={_id}
              bgIcon={segmentBg}
              backgroundColor={segmentBg}
              text={segmentNameForTenant}
              path={segment.path}
              backgroundUrl={fileFn && `${apiUrl}tenant/module-photo/${selectedPlatormId}/${_id}`}
            />
          );
        })}
      <UserActionBox
        key="1"
        backgroundColor="#000"
        text={__('application.configStaticSites')}
        path="/dashboard/static-sites"
      />
    </ActionsBoxesContainer>
  );
};
