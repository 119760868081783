import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from '../reducers';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['platformConfig', 'user'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        'PREPARE_MODAL',
        'SHOW_MODAL',
        'HIDE_MODAL',
        'UNPREPARE_MODAL',
        'SUCCESS_NOTIFICATION',
        'HIDE_NOTIFICATION',
      ],
    },
  }),
});

export const persistor = persistStore(store);
