import React, { FC, useEffect } from 'react';
import { useListArticle } from '../../../hooks/articles/useListArticle';
import { NewsCarosuel } from './NewsCarousel';

import { Spinner } from '..';
import { Container } from '../../Layout';

const HomeNewsMobile: FC = () => {
  const { articles, promoted, loading, isNewsSection, isPromoted } = useListArticle();

  useEffect(() => {
    isNewsSection();
  }, []);

  if (loading) return <Spinner />;
  if ((articles && articles.length !== 0) || isPromoted) {
    return (
      <section>
        <Container>
          <NewsCarosuel news={[...promoted, ...articles]} />
        </Container>
      </section>
    );
  } else return <></>;
};

export default HomeNewsMobile;

