import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Answer,
  NextQuestionRes,
  QuizDetailsRes,
  QuizQuestion,
  CheckAnswerReq,
  FinishQuizRes,
  QuizStatus,
} from 'blocal-types';

import { useApi } from '../../services/useApi';
import { apiUrl } from '../../config/apiUrl';
import { history } from '../../App';

interface UseQuiz {
  quizInfo: QuizDetailsRes | null;
  question: QuizQuestion | null;
  quizId: string;
  questionNumber: number | null;
  quizLength: number | null;
  loading: boolean;
  survey: boolean;
  isLastQuestionRunning: () => boolean;
  checkAnswer: (quizId: string, questionId: string, answersIds: string[], survey?: boolean, data?: { content?: string, answerId?: string}, isQuestionOpen?: boolean) => void;
  getPoints: () => void;
  runQuiz: () => void;
  getQuizInfo: (redirect?: boolean) => void;
  getQuizPhoto: (name: string) => void;
  setSurvey: React.Dispatch<React.SetStateAction<boolean>>;
  correctAnswers: string[];
  checkedAnswers: number[];
  points: number | null;
  getAnswer: (answer: number) => void;
  submitQuiz: (actionType: 'submit' | 'next' | 'finish') => void;
  setCheckedAnswers: (answers: number[]) => void;
  handleSurveySubmit: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useQuiz = (): UseQuiz => {
  const { api, loading } = useApi();
  const [quizInfo, setQuizInfo] = useState<QuizDetailsRes | null>(null);
  const [question, setQuestion] = useState<QuizQuestion | null>(null);
  const [survey, setSurvey] = useState<boolean>(false);
  const [points, setPoints] = useState<number | null>(null);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [quizLength, setQuizLength] = useState<number | null>(null);
  const [questionNumber, setQuestionNumber] = useState<number | null>(null);
  const { quizId } = useParams<{ quizId: string }>();
  const [checkedAnswers, setCheckedAnswers] = useState<number[]>([]);

  const getAnswer = (answer: number) => {
    if (!checkedAnswers.includes(answer)) {
      setCheckedAnswers([...checkedAnswers, answer]);
    } else {
      const cleanedAnswers = checkedAnswers && checkedAnswers.filter((ans) => ans !== answer);
      setCheckedAnswers(cleanedAnswers);
    }
  };

  const isLastQuestionRunning = () => {
    if (quizLength && questionNumber === quizLength - 1) return true;
    return false;
  };

  const getQuizInfo = (redirect?: boolean) => {
    setQuizInfo(null);
    api<QuizDetailsRes>({
      method: 'GET',
      path: `quiz/details/${quizId}`,
      onSuccess: (data) => {
        setQuizInfo(data);
        if (data.quizStatus === QuizStatus.Finished && redirect) {
          if (survey) {
            history.push(`/dashboard/quizzes/surveys/preview/${quizId}`);
          } else history.push(`/dashboard/quizzes/preview/${quizId}`);
        }
      },
    });
  };

  const getPoints = () => {
    api<FinishQuizRes>({
      method: 'PATCH',
      path: `quiz/show-result/${quizId}`,
      onSuccess: (data) => {
        setPoints(data.finalPoints);
        getQuizInfo();
      },
    });
  };

  const runQuiz = () => {
    setQuestion(null);
    setCorrectAnswers([]);
    api<NextQuestionRes>({
      method: 'POST',
      path: `quiz/run/${quizId}`,
      onSuccess: (data) => {
        if (data?.length) setQuizLength(data.length);
        if (data?.index || data?.index === 0) setQuestionNumber(data.index);
        if (data?.question) {
          setQuestion(data?.question);
        } else {
          getPoints();
        }
      },
    });
  };

  const getQuizPhoto = (name: string) => `${apiUrl}quiz/image/${name}/${quizId}`;

  const checkAnswer = (quizId: string, questionId: string, answersIds: string[], survey?:boolean, payload?: { content?: string, answerId?: string }, isQuestionOpen?: boolean) => {
    if (survey || isQuestionOpen) {
      api<NextQuestionRes, CheckAnswerReq>({
        method: 'POST',
        path: 'quiz/check-answer',
        onSuccess: (data) => {
          setCheckedAnswers([]);
          getQuizInfo(true);
          if (data?.length) setQuizLength(data.length);
          if (data?.index || data?.index === 0) setQuestionNumber(data.index);
          setQuestion(data?.question);
        },
        data: {
          quizId,
          questionId,
          answersIds,
          content: payload?.content,
        },
      });
    } else {
      api<Answer[], CheckAnswerReq>({
        method: 'POST',
        path: 'quiz/check-answer',
        onSuccess: (data) => {
          getQuizInfo();
          const checkedAnswers = data.filter((ans: Answer) => ans.isCorrect);
          setCorrectAnswers(checkedAnswers.map((a: Answer) => a._id));
        },
        data: {
          quizId,
          questionId,
          answersIds,
        },
      });
    }
  };

  const handleSurveySubmit = () => {
    const givenAnswers = question?.answer ? question?.answer.filter((_an: Answer, index: number) => checkedAnswers.includes(index)) : [];
    const answersIds = givenAnswers ? givenAnswers.map((ans: Answer) => ans._id) : [];
    if (question?._id) checkAnswer(quizId, question._id, answersIds, true);
  };

  const submitQuiz = (actionType: 'submit' | 'next' | 'finish') => {
    switch (actionType) {
      case 'submit':
        const givenAnswers = question?.answer ? question?.answer.filter((_an: Answer, index: number) => checkedAnswers.includes(index)) : [];
        const answersIds = givenAnswers ? givenAnswers.map((ans: Answer) => ans._id) : [];
        if (question?._id) checkAnswer(quizId, question._id, answersIds);
        break;
      case 'next':
        setCheckedAnswers([]);
        runQuiz();
        break;
      case 'finish':
        getPoints();
        getQuizInfo(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (quizId) {
      getQuizInfo();
    }
  }, []);

  return {
    quizInfo,
    question,
    questionNumber,
    quizLength,
    quizId,
    runQuiz,
    getQuizPhoto,
    loading,
    checkAnswer,
    correctAnswers,
    getPoints,
    getQuizInfo,
    points,
    checkedAnswers,
    setCheckedAnswers,
    getAnswer,
    submitQuiz,
    handleSurveySubmit,
    isLastQuestionRunning,
    survey,
    setSurvey,
  };
};
