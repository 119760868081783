import React, { FC } from 'react';

import { IconButton } from '..';

// eslint-disable-next-line import/prefer-default-export
export const CarouselButton: FC = ({ children }) => (
  <IconButton width="30px" height="30px" mr={5} ml={5} bRadius="50%" bg="transparent" withShadow>
    {children}
  </IconButton>
);
