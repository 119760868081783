import React from 'react';
import Pagination from '../Pagination';
import styled from 'styled-components';

interface Props {
  scheme: any;
  data: any;
  component: React.ComponentClass<any> | React.StatelessComponent<any>;
  narrow?: boolean;
  initialPage?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  id?: string;
}

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const List = ({ data, initialPage, onPageChange, component, scheme, page }: Props) => (
  <>
    <StyledList>
      {data.items.map((item: any, index: number) =>
        React.createElement(component, {
          item,
          index,
          key: `list-key-${index.toString()}`,
          initialPage,
        }),
      )}
    </StyledList>
    <Pagination
      page={page}
      initialPage={initialPage || 1}
      pageCount={Math.ceil(data.countTotal / scheme.countPerPage)}
      onPageChange={(page: number) => onPageChange && onPageChange(page)}
    />
  </>
);

export default List;
