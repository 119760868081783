import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { UserRole } from 'blocal-types';
import { Link } from 'react-router-dom';
import { gtMedia } from '../../../config/config';
import { RootState } from '../../../reducers';
import Icon from '../Icon';
import { usePlatform } from '../../../hooks/usePlatform';

interface Props {
  path: string;
  backgroundColor: string;
  text: string;
  bgIcon?: string;
  backgroundUrl?: string;
}

interface StyledLinkProps {
  $isSuperUser?: boolean;
}

interface StyledContentWrapperProps {
  backroundColor?: string;
  backgroundUrl?: string;
}

export const StyledActionText = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.colorLight};
  font-weight: 900;
  position: relative;
  z-index: 1;
`;

export const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  position: relative;
  background-color: ${({ backroundColor }) => backroundColor ?? '#000'};
  background-image: ${({ backgroundUrl }) => `url(${backgroundUrl})` ?? 'none'};
  box-shadow: ${({ theme }) =>
    `0 0 calc(1.5 * ${theme.mainSpacing}) calc(-0.5 * ${theme.mainSpacing}) ${rgba(theme.colors.colorDark, 0.25)}`};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.mainSpacing};
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
`;

const StyledLink = styled(Link)<StyledLinkProps>`
  height: 150px;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing}/2)`};
  text-decoration: none;
  flex: 0 0 50%;
  position: relative;
  ${gtMedia('md')} {
    flex: 0 0 25%;
  }

  ${({ $isSuperUser }) =>
    $isSuperUser &&
    css`
      ${gtMedia('md')} {
        flex: 0 0 33.33%;
      }
    `}
`;

const StyledA = styled.a<StyledLinkProps>`
  height: 150px;
  margin-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `0 calc(${theme.mainSpacing}/2)`};
  text-decoration: none;
  flex: 0 0 50%;
  position: relative;
  ${gtMedia('md')} {
    flex: 0 0 25%;
  }

  ${({ $isSuperUser }) =>
    $isSuperUser &&
    css`
      ${gtMedia('md')} {
        flex: 0 0 33.33%;
      }
    `}
`;

const StyledImage = styled.img`
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
`;

const UserActionBox: FC<Props> = ({ backgroundColor, bgIcon, text, path, backgroundUrl }) => {
  const user = useSelector((state: RootState) => state.user.details);
  const host = useSelector((state: RootState) => state.platformConfig?.config?.host);
  const { tenantQuery } = usePlatform();

  const isExternal = (dest: string) => {
    if (!path.includes('http://') && !path.includes('https://')) return false;
    return !path.includes(host);
  };

  const getProperPath = (dest: string) => {
    if (path.includes(host)) return path.replace(window.location.origin, '');
    return path;
  };

  const content = (
    <>
      <StyledContentWrapper backroundColor={backgroundColor}>
        {bgIcon && <Icon icon={bgIcon} userIcon />}
        {backgroundUrl && <StyledImage src={`${backgroundUrl}/${tenantQuery}`} />}
        <StyledActionText>{text}</StyledActionText>
      </StyledContentWrapper>
    </>
  );

  if (isExternal(path)) {
    return (
      <StyledA $isSuperUser={user?.role === UserRole.SuperUser} role="button" href={path} target="_blank">
        {content}
      </StyledA>
    );
  }
  return (
    <StyledLink $isSuperUser={user?.role === UserRole.SuperUser} role="button" to={getProperPath(path)}>
      {content}
    </StyledLink>
  );
};

export default UserActionBox;
