import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorCode } from 'blocal-types';
import { errorNotification } from '../actions/notifications';
import { history } from '../App';
import { store } from '../config/store';
import { Colors } from '../config/styled';
import { theme } from '../config/theme';
import { apiService } from '../services/apiService';
import { logout } from './userSlice';

export interface PlatformConfig {
  host: string;
  config: any;
  loading: boolean;
  colors: Colors;
  tenantFbPageID: string;
  fontPrimary: string;
  basicForms: any[];
  boundType?: any;
  borderRadius?: number;
}

const handleConfigErrorResponse = (err: any) => {
  const { response } = err;
  if (response.data.error_code === ApiErrorCode.TenantIsDeactivated) {
    history.push('/not-active');
  } else {
    store.dispatch(errorNotification(response.data.error_code));
  }
};

export const getPlatformConfig = createAsyncThunk(
  'init',
  async () =>
    await apiService(
      `tenant/init/${window.location.hostname}`,
      'GET',
      undefined,
      handleConfigErrorResponse,
      true,
    ),
);

export const getFullPlatformConfig = createAsyncThunk('init', async () => {
  try {
    const config = await apiService(
      `tenant/init-full/${window.location.hostname}`,
      'GET',
      undefined,
      undefined,
      true,
    );
    return config;
  } catch (error) {
     store.dispatch(logout(null));
  }
});

export const platformConfig = createSlice({
  name: 'lang',
  initialState: {} as PlatformConfig,
  reducers: {},
  extraReducers: {
    [getPlatformConfig.pending as any]: (state) => {
      state.loading = true;
    },
    [getPlatformConfig.fulfilled as any]: (state, action: PayloadAction<PlatformConfig>) => {
      if (action.payload) {
        const {
          colors,
          fontPrimary,
          basicForms,
          boundType,
          borderRadius,
          ...config
        } = action.payload;
        state.config = config;
        state.colors = colors;
        state.borderRadius = borderRadius;
        state.fontPrimary = fontPrimary;
        state.boundType = boundType;
        state.basicForms = basicForms;
        state.tenantFbPageID = action.payload.tenantFbPageID;
      }
      state.loading = false;
    },
    [getPlatformConfig.rejected as any]: (state, action: PayloadAction<null>) => {
      state.loading = false;
      state.colors = theme.colors;
    },
  },
});

export default platformConfig.reducer;
