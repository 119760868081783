// @ts-nocheck
import Image from '@editorjs/image';
// import Paragraph from 'editorjs-paragraph-with-alignment';
import List from '@editorjs/list';
import Header from '@editorjs/header';
import Embed from '@editorjs/embed';
import Paragraph from '@editorjs/paragraph';
import Table from '@editorjs/table';
import HyperLink from 'editorjs-hyperlink';
import { apiUrl } from './apiUrl';

interface EditorJsToolsProps {
  _id: string;
  disablePhoto?: boolean;
}

export const editorJsTools = ({ _id, disablePhoto }: EditorJsToolsProps) => {
  const modulesWithoutImage = {
    list: {
      class: List,
      inlineToolbar: true,
      styles: ['unordered', 'ordered'],
    },
    header: {
      class: Header,
      levels: [2, 3, 4],
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeo: {
            regex: /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
            embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
            html:
              '<iframe style="width:100%;" height="320" frameborder="0" mozallowfullscreen webkitallowfullscreen allowfullscreen style="--aspect-ratio: 320 / 560;"></iframe>',
            height: 320,
            width: 580,
          },
        },
      },
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 1,
        cols: 1,
      },
    },
    hyperlink: {
      class: HyperLink,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer'],
      },
    },
  };

  const modulesWithImage = {
    image: {
      class: Image,
      config: {
        additiionalRequestHeaders: {
          'with-credentials': true,
        },
        endpoints: {
          byFile: `${apiUrl}article/add-single-photo/${_id}`, // Your backend file uploader endpoint
          byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
        },
      },
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeo: {
            regex: /(?:http[s]?:\/\/)?(?:www.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
            embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0',
            html:
              '<iframe style="width:100%;" height="320" frameborder="0" mozallowfullscreen webkitallowfullscreen allowfullscreen style="--aspect-ratio: 320 / 560;"></iframe>',
            height: 320,
            width: 560,
          },
        },
      },
    },
    list: {
      class: List,
      inlineToolbar: true,
      styles: ['unordered', 'ordered'],
    },
    header: {
      class: Header,
      levels: [2, 3, 4],
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    table: {
      class: Table,
      inlineToolbar: true,
      config: {
        rows: 1,
        cols: 1,
      },
    },
    hyperlink: {
      class: HyperLink,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer'],
      },
    },
  };
  return disablePhoto ? modulesWithoutImage : modulesWithImage;
};
