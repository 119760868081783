import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { FormField } from '..';
import { useTenant } from '../../../hooks/useTenant';
import { RootState } from '../../../reducers';
import { inputSelectStyles } from '../../../helpers/additionalStyles';
import { __ } from '../../../helpers/i18n';

// eslint-disable-next-line import/prefer-default-export
export const SelectTenantAndLoadModules: FC = () => {
  const { tenants, handleLoadActiveModuleAndSelectTenand } = useTenant();
  const { control } = useFormContext();
  const tenant = useSelector((state: RootState) => state.selectedTenantAndModules.tenant);

  return (
    <FormField
      labelText="Wybierz platformę *"
      type="select"
      name=""
      defaultValue={tenant}
      field={
        <Controller
          name="selectTenant"
          control={control}
          render={({ value, name }) => (
            <Select
              options={tenants.map((tenantOption) => ({ value: tenantOption._id, label: tenantOption.text }))}
              styles={inputSelectStyles()}
              onChange={(e: any) => handleLoadActiveModuleAndSelectTenand(e)}
              name={name}
              noOptionsMessage={() => __('application.noOptions')}
              placeholder={__('application.choose')}
              value={value || tenant}
            />
          )}
        />
      }
    />
  );
};
