import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SelectTenantAndLoadModules } from '../CommonInputs';
import { SectionTitle } from '..';
import { __ } from '../../../helpers/i18n';

// eslint-disable-next-line import/prefer-default-export
export const SelectTenantToOrder: FC = () => {
  const methods = useForm({
    mode: 'onChange',
  });

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <SelectTenantAndLoadModules />
        </form>
      </FormProvider>
      <SectionTitle>{__('application.moduleActionsForChosenPlatformManagement')}</SectionTitle>
    </>
  );
};
