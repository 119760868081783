import { Permission, UserRole } from 'blocal-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { refreshUserDetails } from '../slices/userSlice';

interface UseUserAccess {
  hasAccess: (permission: Permission) => boolean | null;
}

type PermissionOption = {
  value: Permission;
  label: string;
};

// eslint-disable-next-line import/prefer-default-export
export const useUserAccess = (): UseUserAccess => {
  const user = useSelector((state: RootState) => state.user?.details);
  const dispatch = useDispatch();

  if (!user) dispatch(refreshUserDetails());

  const hasAccess = useCallback(
    (permission: Permission) => {
      if (user!.role === UserRole.User) return false;
      if (![UserRole.Admin, UserRole.SuperAdmin].includes(user!.role)) {
        return user?.permissions && user?.permissions.length > 0
          ? user?.permissions?.some((userPermission: PermissionOption) => userPermission.value === permission)
          : null;
      }
      return true;
    },
    [user],
  );

  return { hasAccess };
};
