/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { ValueAndLabelObj } from 'blocal-types';
import styled from 'styled-components';

interface ArticleCategoriesProps {
  categories: ValueAndLabelObj[];
}

const StyledCategory = styled.div`
  color: ${({ theme }) => theme.colors.colorLight};
  margin-right: ${({ theme }) => `calc(${theme.mainSpacing} * 1.5)`};
  padding: ${({ theme }) => `calc(${theme.mainSpacing})`};
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  border-radius: ${({ theme }) => `${theme.borderRadius / 2}px`};
`;

const StyledArticleCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: ${({ theme }) => `calc(${theme.mainSpacing} * 2)`};
`;

// eslint-disable-next-line import/prefer-default-export
export const ArticleCategories: FC<ArticleCategoriesProps> = ({ categories }) => {
  return (
    <StyledArticleCategories>
      {categories.map((category: ValueAndLabelObj) => {
        return <StyledCategory>#{category.label}</StyledCategory>;
      })}
    </StyledArticleCategories>
  );
};
