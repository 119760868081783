import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setHeader } from '../slices/headerSlice';

const useHeader = (title: string, back?: string, noLeft?: boolean, hideOnMobile?: boolean, hide?: boolean): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeader({ 
        title, 
        back: back ?? '',
        noLeft: noLeft ?? false,
        hideOnMobile: hideOnMobile ?? false,
        hide: hide ?? false })
      );
  }, [title, back, noLeft, hideOnMobile, hide, dispatch]);
};
export default useHeader;
