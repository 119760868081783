import styled, { css } from 'styled-components';

export const StyledViewsTabs = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.colorInput};
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  display: flex;
  height: 50px;
  text-align: center;
  overflow: hidden;
  margin-bottom: ${({ theme }) => `calc(3 * ${theme.mainSpacing})`};
`;

export const StyledTab = styled.div<{ isFirst: boolean; isActive: boolean; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  height: 100%;
  max-width: ${({ width }) => `${width}%`};
  font-weight: 200;
  padding: ${({ theme }) => theme.mainSpacing};
  // color: ${({ theme }) => theme.colors.colorDisabled};
  ${({ isFirst }) =>
    !isFirst &&
    css`
      border-left: 1px solid ${({ theme }) => theme.colors.colorInput};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.colorLight};
      cursor: default;
      background: ${({ theme }) => theme.colors.colorSecondary};
      border: none;
    `}
`;
