import clsx from 'clsx';
import React, { useRef } from 'react';
import './ModalContent.scss';

interface Props {
  children: React.ReactNode;
  light?: boolean;
  minHeight?: number;
}

const ModalContent = ({ children, light, minHeight }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  // const [maxHeight, setMaxHeight] = useState<number>(150);

  // const observer = new ResizeObserver((entries: any) => {
  //   entries.forEach((entry: any) => {
  //     setMaxHeight(Math.round(entry.contentRect.height) + 25);
  //   });
  // });

  // useEffect(() => {
  //   observer.observe(ref.current!);
  // }, []);

  return (
    <div style={{ minHeight: minHeight ?? 'auto' }} className={clsx('modal-content', { light })}>
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default ModalContent;
