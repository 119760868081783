/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-tabs */
import React from 'react';
import { FieldType, TableResponse, TableSchema, TableSchemaField } from 'blocal-tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Button, Alert, Pagination } from '../../Common';
import { formatPrice } from '../../../utils/formatPrice';
import clsx from 'clsx';
import { ButtonsContainer } from '../../Layout';
import { __ } from '../../../helpers/i18n';

interface Props {
  scheme: TableSchema;
  data: TableResponse<any>;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  sort?: {
    sortBy: string;
    sortDirBack: boolean;
  };
  onSortChange?: (sortBy: string, sortDirBack: boolean) => void;
  lastChildCenter?: boolean;
  page?: number;
  highlight?: (row: any) => boolean;
  dehighlight?: (row: any) => boolean;
}
const formatValue = (value: any, field: TableSchemaField, allValues: any) => {
  if (typeof field.customValue === 'function') {
    value = field.customValue(allValues, field);
  }
  switch (field.type) {
    case FieldType.Custom: {
      return field.customRender && field.customRender(value, field, allValues);
    }
    case FieldType.Url: {
      return value === null ? (
        '-'
      ) : (
        <a href={value} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    }
    case FieldType.Email: {
      return value === null ? (
        '-'
      ) : (
        <a href={`mailto:${value}`} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    }
    case FieldType.Tel: {
      return value === null ? (
        '-'
      ) : (
        <a href={`tel:${value}`} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    }
    case FieldType.Img: {
      return value !== null && <img src={value} alt={field.name} />;
    }
    case FieldType.Date: {
      return value === null || value === undefined ? 'Nieustalono' : new Date(value).toLocaleString();
    }
    case FieldType.Boolean: {
      return value === null ? 'Nie' : value ? 'Tak' : 'Nie';
    }
    case FieldType.Price: {
      return value === (null || undefined) ? '-' : formatPrice(value);
    }
    case FieldType.Percent: {
      return value === (null || undefined) ? '-' : `${value}%`;
    }
    case FieldType.Text:
    default: {
      return !value ? '-' : value;
    }
  }
};

const getValue = (field: string, row: any) => {
  if (field.includes('.')) {
    const splitted = field.split('.');
    if (row[splitted[0]]) return row[splitted[0]][splitted[1]];
    else return '-';
  }
  return row[field];
};
function genSortItems(
  header: TableSchemaField,
  sort: { sortBy: string; sortDirBack: boolean },
  onSortChange: (sortBy: string, sortDirBack: boolean) => void,
) {
  if (header.sortable !== true) return null;
  const handleSortChange = (dirBack: boolean) => {
    onSortChange(header.field, dirBack);
  };
  const arrow1 = (
    <a
      onClick={() => handleSortChange(false)}
      className={`sort sort-up ${sort.sortBy === header.field && sort.sortDirBack !== true ? 'active' : ''}`}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </a>
  );
  const arrow2 = (
    <a
      onClick={() => handleSortChange(true)}
      className={`sort sort-down ${sort.sortBy === header.field && sort.sortDirBack === true ? 'active' : ''}`}
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </a>
  );
  return (
    <>
      {arrow1}
      {arrow2}
    </>
  );
}
class Table extends React.Component<Props> {
  state = {
    toggled: -1,
  };

  private toggleRow = (index: number) => {
    if (index === this.state.toggled) {
      this.setState({ toggled: -1 });
    } else this.setState({ toggled: index });
  };

  render() {
    const { highlight, dehighlight, page } = this.props;
    const desktop = window.matchMedia('(min-width: 920px)').matches;
    let content = null;
    if (!this.props.data || this.props.data.items.length === 0) {
      return <Alert simple type="error" text="Brak danych do wyświetlenia." />;
    }
    if (desktop) {
      const headers = this.props.scheme.fields.map((header: TableSchemaField) => (
        <th className={`${header.mobile ? 'mobile' : ''}`} key={header.field}>
          {__(header.name)}{' '}
          {this.props.sort && this.props.onSortChange && genSortItems(header, this.props.sort, this.props.onSortChange)}
        </th>
      ));
      const rows = this.props.data.items.map((row: any, rowIndex: number) => (
        <tr
          key={rowIndex}
          className={clsx({ highlight: highlight && highlight(row), dehighlight: dehighlight && dehighlight(row) })}
        >
          {this.props.scheme.fields.map((header: TableSchemaField, index: number) => (
            <td className={`${header.mobile ? 'mobile' : ''}`} key={`${header.field}_${rowIndex}`}>
              {header.buttons ? (
                <ButtonsContainer>
                  {header.buttons.map((button: any, index: number) => (
                    <Button
                      small
                      key={index}
                      variant={button.type}
                      to={button.to && `${button.to}${row.id}`}
                      onClick={button.click ? () => button.click(row) : null}
                    >{button.name}</Button>
                  ))}
                </ButtonsContainer>
              ) : (
                formatValue(getValue(header.field, row), header, row)
              )}
            </td>
          ))}
        </tr>
      ));
      content = (
        <table className={`table ${this.props.lastChildCenter ? 'last-child-center' : ''}`}>
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      );
    } else {
      content = (
        <div className="list">
          {this.props.data.items.map((row: any, rowIndex: number) => (
            <div
              className={`list-element ${this.state.toggled === rowIndex ? 'toggled' : ''}`}
              onClick={() => this.toggleRow(rowIndex)}
              key={rowIndex}
            >
              {this.props.scheme.fields.map((header: TableSchemaField, index: number) => (
                <div className={`list-row ${header.mobile || header.field === 'actions' ? 'mobile' : ''}`}>
                  {header.buttons ? (
                    <div className="list-buttons">
                      {header.buttons.map((button: any, index: number) => (
                        <Button
                          small
                          key={index}
                          variant={button.type}
                          to={button.to ? `${button.to}${row.id}` : ''}
                          onClick={button.click ? () => button.click(row) : null}
                          text={button.name}
                        />
                      ))}
                    </div>
                  ) : header.field === 'actions' ? (
                    <>
                      <span className="list-buttons">{formatValue(row[header.field], header, row)}</span>
                    </>
                  ) : (
                    <>
                      <strong>{__(header.name)}</strong>
                      <span className="list-value">{formatValue(row[header.field], header, row)}</span>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return (
      <>
        <div className="table-container">{content}</div>
        <Pagination
          initialPage={this.props.initialPage || 1}
          page={page}
          pageCount={Math.ceil(this.props.data.countTotal / this.props.scheme.countPerPage)}
          onPageChange={(page: any) => this.props.onPageChange && this.props.onPageChange(page)}
        />
      </>
    );
  }
}
export default Table;
